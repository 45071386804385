import { useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import { ObservationItemFormValues } from '../../ObservationFormApp';
import { useProjects } from '../../../../services/projectsContext';
import { IProject } from '../../../../schemas/interfaces';
import { ObsListDetailsFormValues } from '../ObsListDetailsForm';
import ItemProjectForm from './ProjectForm';
import { ProjectCategory } from '../../../../schemas/enums';
import { useParams } from 'react-router-dom';

const ObsItemFormProjects: FC<{ list: ObsListDetailsFormValues; newList?: boolean }> = ({ list, newList }) => {
    const { values } = useFormikContext<ObservationItemFormValues>();
    const { getActiveProjects, projects: allProjects } = useProjects();
    const [itemProjects, setItemProjects] = useState<IProject[]>([]);
    const { projectSlugs } = useParams<{ projectSlugs: string }>();

    useEffect(() => {
        if (!values || !list.date || !list.municipalityPartId || !getActiveProjects || !allProjects) return;

        const itemAssignedProjects = allProjects
            .filter((p) => p.category.includes(ProjectCategory.listItemEdit))
            .filter((p) => values.projects?.includes(p.id));

        if (!newList || !values.taxonId) return setItemProjects(itemAssignedProjects);

        const slugsArray = projectSlugs?.split('+').filter((slug) => slug);

        setItemProjects(
            getActiveProjects(list.date, list.municipalityPartId, values.taxonId, slugsArray).filter((p) =>
                p.category.includes(ProjectCategory.listItemInsert),
            ),
        );
    }, [values.taxonId, list.date, list.municipalityPartId, getActiveProjects, allProjects]);

    return (
        <>
            {itemProjects.map((project) => (
                <ItemProjectForm key={project.id} project={project} />
            ))}
        </>
    );
};

export default ObsItemFormProjects;
