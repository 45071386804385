import { Grid, Paper, Typography, Tab, Tabs, Button, Chip, Alert, Tooltip } from '@mui/material';
import { Box, Container } from '@mui/system';
import { useState, useEffect, FunctionComponent, ReactNode } from 'react';
import { useParams } from 'react-router';
import MasonryImage from '../../components/gallery/MasonryImage';
import ObsItemDefinitionList from '../../components/ObsItemDefinitionList';
import ObsListDefinitionList from '../../components/ObsListDefinitionList';
import { useApi } from '../../services/apiContext';
import { IList, IListItem, IPlace, ITag, ITaxon } from '../../schemas/interfaces';
import {
    allDictionaryProvider,
    placesDictionaryProvider,
    taxonsDictionaryProvider,
} from '../../services/dictProviders';
import CopyToClipboard from '../../components/map/CopyToClipboard';
import Lightbox from '../../components/gallery/Lightbox';
import ItemProjectDefinitionList from './components/ItemProjectDefinitionList';
import { ListItemTags, ListRoles, RarityLevel } from '../../schemas/enums';
import { CommentsThread } from '../../components/comments/CommentsThread';
import { ContactSupportRounded, LinkOutlined, ListAltOutlined, ShareOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Masonry from 'react-responsive-masonry';
import ItemActionsButton from '../../components/ItemActionsButton';
import ObservationMap from '../../components/map/ObservationMap';
import ItemRarityIcon from '../../components/grid/ItemRarityIcon';
import NotFound from '../../components/NotFound';
import Loading from '../../components/Loading';
import { TooltipMobile } from '../../components/Tooltip';

enum ObsItemViewAppTabs {
    Map = 'Map',
    Photo = 'Photo',
}

const PHOTO_COUNT_BEFORE_CLICKING_SHOW_ALL_PHOTOS = 7;

interface ObsItemViewAppState {
    id: number;
    activeTab?: ObsItemViewAppTabs;
    list?: IList;
    item?: IListItem;
    notFound: boolean;
    loadAllPhotos: boolean;
    lightboxOpenOnPicture?: number;
    addingComment?: boolean;
    taxonsDict?: ITaxon[];
    tagDicts?: ITag[];
    placeDict?: IPlace[];
}

export const ObsItemViewApp: FunctionComponent = () => {
    const params = useParams();
    const [state, setState] = useState<ObsItemViewAppState>({
        id: parseInt(params.id as string),
        notFound: false,
        loadAllPhotos: false,
    });
    const api = useApi();

    useEffect(() => {
        api.getListItem(state.id)
            .then((response) => {
                const observation = response.items.find((item) => item.id === state.id);

                if (!observation)
                    throw new Error("List obtained from API but it doesn't contain item with the current id.");

                setState((state) => ({
                    ...state,
                    list: response,
                    item: observation,
                    activeTab:
                        observation.media && observation.media.length > 0
                            ? ObsItemViewAppTabs.Photo
                            : observation.coordinates || response.location.coordinates
                            ? ObsItemViewAppTabs.Map
                            : undefined,
                }));
            })
            .catch((e) => {
                console.log(e);
                setState((state) => ({ ...state, notFound: true }));
            });
    }, [state.id]);

    useEffect(() => {
        taxonsDictionaryProvider
            .onFirstValue()
            .then((response) => setState((state) => ({ ...state, taxonsDict: response })));
        allDictionaryProvider
            .onFirstValue()
            .then((response) => setState((state) => ({ ...state, tagDicts: response.tags })));
        placesDictionaryProvider
            .onFirstValue()
            .then((response) => setState((state) => ({ ...state, placeDict: response.items })));
    }, []);

    if (state.notFound)
        return <NotFound text={`Pozorování s\xa0ID ${state.id} nebylo nalezeno.`} className="ObsItemViewApp" />;

    if (!state.list || !state.item) return <Loading fullPage />;

    const { id: itemId, taxonId, media, comments, rarity, coordinates } = state.item;
    const { role: listRole } = state.list;
    const taxon = state.taxonsDict && state.taxonsDict.find((taxon) => taxon.id === taxonId);
    const isUnsure = (state.item.tags || []).includes('uncertain');
    const tags = (state.tagDicts || []).filter((tag) =>
        (state.item?.tags || []).some((itemTag) => itemTag === tag.label),
    );

    return (
        <Box className="ObsItemViewApp" sx={{ pt: 5.75, pb: 6.75 }}>
            <Container maxWidth="lg">
                <Paper elevation={4} sx={{ py: 3, px: 2.5 }}>
                    <Grid container spacing={0} columnSpacing={3.5}>
                        <Grid
                            item
                            xs={12}
                            sx={{ pb: 1.75, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
                        >
                            <Typography variant="h2" component="h1">
                                {isUnsure && <span>? </span>}
                                {!state.taxonsDict ? (
                                    'Loading...'
                                ) : taxonId === null || !taxon ? (
                                    `neurčený druh`
                                ) : (
                                    <>
                                        <Box component="span" fontWeight="bold">
                                            {taxon.name.cs}
                                        </Box>
                                        <Box
                                            component="span"
                                            fontWeight="400"
                                            fontStyle="italic"
                                            sx={{ opacity: 0.8, letterSpacing: '1px' }}
                                        >
                                            {' '}
                                            &mdash; {taxon.name.la}
                                        </Box>
                                    </>
                                )}
                            </Typography>
                            <ItemActionsButton item={state.item} list={state.list} />
                        </Grid>
                        <Grid container item xs={12} md={6} spacing={2.25} alignContent="flex-start">
                            <Grid item xs={12} md={6}>
                                <CopyToClipboard
                                    label="ID pozorování"
                                    copyValue={`https://cso.cz/${itemId}`}
                                    value={String(itemId)}
                                    customIcon={<ShareOutlined fontSize="small" />}
                                    copiedMessage="Odkaz pro&nbsp;sdílení zkopírován do&nbsp;schránky!"
                                    typographyProps={{
                                        variant: 'body2',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '8px',
                                        justifyContent: 'flex-end',
                                        '& .MuiChip-root': {
                                            pointerEvents: 'none',
                                            '.MuiSvgIcon-root': {
                                                pl: 1,
                                            },
                                        },
                                    }}
                                >
                                    {listRole == ListRoles.owner && <Chip label="Jste autorem" />}
                                    {listRole == ListRoles.coobserver && <Chip label="Jste spolupozorovatelem" />}
                                    {rarity == RarityLevel.remarkable && (
                                        <Chip label="Zajímavé pozorování" icon={<ItemRarityIcon item={state.item} />} />
                                    )}
                                    {rarity == RarityLevel.uncommon && (
                                        <Chip
                                            label="Neobvyklé pozorování"
                                            icon={<ItemRarityIcon item={state.item} />}
                                        />
                                    )}
                                    {rarity == RarityLevel.rare && (
                                        <Chip label="Vzácné pozorování" icon={<ItemRarityIcon item={state.item} />} />
                                    )}
                                    {tags
                                        .filter((t) => t.label !== RarityLevel.remarkable)
                                        .map((tag) => (
                                            <Chip label={tag.name.cs} key={tag.label} />
                                        ))}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper elevation={0} variant="outlined" sx={{ pt: 1, pl: 1 }}>
                                    <ObsItemDefinitionList item={state.item} hideColumns={['taxonId']} />
                                </Paper>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="body1" component="h2" textTransform="uppercase" fontWeight="bold">
                                    Vycházka
                                </Typography>
                                <Link to={`/list/${state.list.publicId}`}>
                                    <Button
                                        size="small"
                                        endIcon={<ListAltOutlined fontSize="small" />}
                                        sx={{
                                            fontWeight: 'regular',
                                            textTransform: 'none',
                                        }}
                                    >
                                        Přejít na&nbsp;detail vycházky
                                    </Button>
                                </Link>
                            </Grid>
                            <Grid item xs={12} sx={{ paddingTop: '5px !important' }}>
                                <Paper elevation={0} variant="outlined" sx={{ pt: 1, pl: 1 }}>
                                    <ObsListDefinitionList
                                        list={state.list}
                                        hideColumns={['speciesCount', 'individualsCountString', 'note']}
                                    />
                                </Paper>
                            </Grid>
                            {state.item.projectsData &&
                                state.item.projectsData.map((project) => (
                                    <Grid item key={project.projectId} xs={12}>
                                        <Typography
                                            variant="body1"
                                            component="h2"
                                            textTransform="uppercase"
                                            fontWeight="bold"
                                            sx={{ pt: 0.5 }}
                                        >
                                            Údaje k&nbsp;projektu {project.name?.cs ?? ''}
                                        </Typography>
                                        <Paper elevation={0} variant="outlined" sx={{ pt: 1, pl: 1, mt: 1.75 }}>
                                            <ItemProjectDefinitionList project={project} />
                                        </Paper>
                                    </Grid>
                                ))}
                        </Grid>
                        <Grid item xs={12} md={6} alignContent="flex-start" sx={{ pt: [2, 0] }}>
                            <Tabs
                                value={state.activeTab}
                                onChange={(e, newValue) => setState({ ...state, activeTab: newValue })}
                                indicatorColor="secondary"
                                variant="fullWidth"
                                textColor="inherit"
                                sx={{ bgcolor: 'primary.main', color: 'white', width: '100%' }}
                            >
                                <Tab label="Fotografie" value={ObsItemViewAppTabs.Photo} disabled={media.length < 1} />
                                <Tab
                                    label="Mapa"
                                    value={ObsItemViewAppTabs.Map}
                                    disabled={!coordinates && !state.list.location.coordinates}
                                />
                            </Tabs>
                            <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', pt: 2.25 }}>
                                <TabPanel currentValue={state.activeTab} thisValue={ObsItemViewAppTabs.Photo}>
                                    {media.length > 0 && (
                                        <MasonryImage
                                            image={media[0]}
                                            sx={{ maxHeight: '400px', width: '100%' }}
                                            hideIcon
                                            disableDialog
                                            onClick={() =>
                                                setState((state) => ({ ...state, lightboxOpenOnPicture: 0 }))
                                            }
                                        />
                                    )}
                                    {media.length > 1 && (
                                        <Box sx={{ pt: 2.25, width: '100%' }}>
                                            <Masonry gutter="1.125rem" columnsCount={2}>
                                                {media
                                                    .slice(
                                                        1,
                                                        state.loadAllPhotos
                                                            ? undefined
                                                            : PHOTO_COUNT_BEFORE_CLICKING_SHOW_ALL_PHOTOS,
                                                    )
                                                    .map((image, index) => (
                                                        <MasonryImage
                                                            image={image}
                                                            key={image.id}
                                                            hideIcon
                                                            disableDialog
                                                            onClick={() =>
                                                                setState((state) => ({
                                                                    ...state,
                                                                    lightboxOpenOnPicture: index + 1,
                                                                }))
                                                            }
                                                            sx={{
                                                                maxHeight: '200px',
                                                            }}
                                                        />
                                                    ))}
                                            </Masonry>
                                            {!state.loadAllPhotos &&
                                                media.length > PHOTO_COUNT_BEFORE_CLICKING_SHOW_ALL_PHOTOS - 1 && (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexWrap: 'wrap',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <Button
                                                            size="small"
                                                            onClick={() =>
                                                                setState((state) => ({ ...state, loadAllPhotos: true }))
                                                            }
                                                            variant="contained"
                                                            sx={{ mt: 3 }}
                                                        >
                                                            Zobrazit všechny fotografie
                                                        </Button>
                                                    </Box>
                                                )}
                                        </Box>
                                    )}
                                    <Lightbox
                                        images={media}
                                        open={state.lightboxOpenOnPicture !== undefined}
                                        openOnImage={state.lightboxOpenOnPicture}
                                        hideCaptionIcons
                                        onClose={() =>
                                            setState((state) => ({ ...state, lightboxOpenOnPicture: undefined }))
                                        }
                                    />
                                </TabPanel>
                                <TabPanel currentValue={state.activeTab} thisValue={ObsItemViewAppTabs.Map}>
                                    <ObservationMap item={state.item} list={state.list} />
                                </TabPanel>
                            </Box>
                            {state.item.tags?.includes(ListItemTags.adviceRequested) && (
                                <TooltipMobile
                                    title="Pozorovatel si není jistý určením druhu. Můžete mu poradit například v&nbsp;komentáři."
                                    placement="bottom-start"
                                >
                                    <Alert
                                        severity="info"
                                        icon={<ContactSupportRounded htmlColor="#fff" />}
                                        sx={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            bgcolor: 'primary.300',
                                            color: 'white',
                                            textTransform: 'uppercase',
                                            fontWeight: 'bold',
                                            lineHeight: '2.2',
                                            mt: 2.25,
                                        }}
                                    >
                                        Žádost o pomoc s&nbsp;určením
                                    </Alert>
                                </TooltipMobile>
                            )}
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ pt: 3.5 }}>
                            <CommentsThread
                                listId={state.list.publicId}
                                itemId={state.item.id}
                                comments={comments || []}
                                locked={!!state.item.commentsLocked}
                                defaultSubject={`Pozorování ${
                                    state.taxonsDict?.find((t) => t.id == taxonId)?.name.cs || 'Loading...'
                                }`}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

const TabPanel: FunctionComponent<{
    currentValue?: ObsItemViewAppTabs;
    thisValue: ObsItemViewAppTabs;
    children: ReactNode;
}> = (props) => {
    return (
        <Box
            sx={{
                display: props.currentValue === props.thisValue ? 'flex' : 'none',
                height: '100%',
                width: '100%',
                flexWrap: 'wrap',
                boxSizing: 'border-box',
            }}
        >
            {props.currentValue === props.thisValue && props.children}
        </Box>
    );
};
