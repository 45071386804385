import { FC, createContext, useContext, PropsWithChildren, useState, useEffect } from 'react';
import { IPredefinedFilter, ITaxon } from '../schemas/interfaces';
import { useAuth } from './authenticator';
import { useApi } from './apiContext';
import { RsGetDicts } from '../schemas/responses';

export interface IDictionaryContext extends Partial<RsGetDicts> {
    taxons?: ITaxon[];
}

export const DictionaryContext = createContext<IDictionaryContext>({} as IDictionaryContext);

export const useDict = () => {
    return useContext(DictionaryContext);
};

export const DictionaryProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    const [dicts, setDicts] = useState<RsGetDicts>();
    const [taxons, setTaxons] = useState<ITaxon[]>();
    const { isLoggedIn } = useAuth();
    const { getDicts, getTaxons } = useApi();

    useEffect(() => {
        if (isLoggedIn === undefined) return;

        getDicts().then((response) => {
            setDicts(response);
        });

        getTaxons().then((response) => {
            setTaxons(response);
        });
    }, [isLoggedIn]);

    return <DictionaryContext.Provider value={{ ...dicts, taxons }}>{children}</DictionaryContext.Provider>;
};
