import {
    Avatar,
    Box,
    Grid,
    Button,
    Paper,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    useMediaQuery,
} from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import IProfileComponentProps from '../../interfaces/IProfileComponentProps';
import MasonryImage from '../../components/gallery/MasonryImage';
import { IObservationFilter, IPlace, IPublicProfile, ITaxon } from '../../schemas/interfaces';
import Loading from '../../components/Loading';
import { placesDictionaryProvider, taxonsDictionaryProvider } from '../../services/dictProviders';
import { format } from 'date-fns';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Lightbox from '../../components/gallery/Lightbox';
import MessageUser from './components/MessageUser';
import { round } from 'lodash';
import { useAuth } from '../../services/authenticator';
import { VisibilityOffRounded } from '@mui/icons-material';
import NotFound from '../../components/NotFound';
import { useApi } from '../../services/apiContext';
import TableIcons from '../../components/TableIcons';
import SecrecyLock from '../../components/dicts/SecrecyLock';

interface ProfileAppState extends ProfileAppParams {
    profile?: IPublicProfile;
    notFound: boolean;
    taxonsDict?: ITaxon[];
    placesDict?: IPlace[];
    lightboxOpenOnPicture?: number;
}

interface ProfileAppParams {
    id: string;
}

export const ProfileApp: React.FunctionComponent<IProfileComponentProps> = (props) => {
    const params = useParams();
    const [state, setState] = useState<ProfileAppState>({
        id: params.id as string,
        notFound: false,
    });

    const isMobile = useMediaQuery('(pointer: coarse)');
    const { isLoggedIn } = useAuth();
    const api = useApi();

    useEffect(() => {
        setState((state) => ({ ...state, id: params.id as string, profile: undefined, notFound: false }));
    }, [params.id]);

    useEffect(() => {
        api.getUserProfileById(state.id)
            .then((profile) => setState((state) => ({ ...state, profile })))
            .catch((err) => {
                console.error(err);
                setState((state) => ({ ...state, notFound: true }));
            });
    }, [state.id]);

    useEffect(() => {
        taxonsDictionaryProvider.onFirstValue().then((dict) => setState((state) => ({ ...state, taxonsDict: dict })));
        placesDictionaryProvider
            .onFirstValue()
            .then((dict) => setState((state) => ({ ...state, placesDict: dict.items })));
    }, []);

    if (state.notFound) return <NotFound text={`Uživatel nebyl nalezen.`} className="ProfileApp" />;

    if (!state.profile || !state.id) return <Loading fullPage />;

    const { publicId, name, bio, statistics, lastMedia, messagingAllowed } = state.profile;
    const { years, yearsSummary, species } = statistics;

    const filter: IObservationFilter = { observer: state.id };
    const queryParams = new URLSearchParams();
    queryParams.append('filter', JSON.stringify(filter));
    const galleryLink = '/search/gallery?' + queryParams.toString();

    return (
        <Container maxWidth="lg" className="ProfileApp" sx={{ pt: 5.75, pb: 6.75 }}>
            <Paper elevation={4} sx={{ px: 2.5, py: 3.75 }}>
                <Grid container spacing={3.5}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2.25}>
                            <Grid item xs={12} md={5}>
                                <Avatar
                                    sx={{
                                        bgcolor: 'primary.main',
                                        width: '100%',
                                        height: 'auto',
                                        aspectRatio: '1 / 1',
                                        fontSize: '5rem',
                                        fontWeight: 'bold',
                                        letterSpacing: '0.6rem',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {/* <Box component="span" sx={{ marginRight: '-0.6rem' }}>
                                        {name
                                            ?.split(' ')
                                            .map((word) => word[0].toUpperCase())
                                            .join('') ?? ''}
                                    </Box> */}
                                </Avatar>
                                {/* <Box
                                    component="img"
                                    src={`https://picsum.photos/seed/${id}/400/400`}
                                    sx={{ width: '100%', maxWidth: '300px' }}
                                /> */}
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Typography variant="h2" component="h1" sx={{ pb: 0.75 }}>
                                    {name}
                                </Typography>
                                <Typography variant="body2" component="p" sx={{ pb: 2.25 }} fontStyle="italic">
                                    Uživatelem od&nbsp;{format(state.profile.registrationDate, 'd. M. yyyy')}
                                </Typography>

                                {/* 
                                TODO: Uncomment when bio is available

                                <Typography
                                    variant="body1"
                                    component="p"
                                    sx={{ pb: 2.25 }}
                                    dangerouslySetInnerHTML={{
                                        __html: bio?.replace(/\n/g, '<br />') ?? '',
                                    }}
                                /> 
                                */}
                                {isLoggedIn === true && !!messagingAllowed && (
                                    <MessageUser recipientId={publicId} recipientName={name ?? ''} />
                                )}
                            </Grid>
                            {!!species && !!species.length && (
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" component="h2">
                                        Pozorované druhy (první pozorování druhu)
                                    </Typography>
                                    <Box sx={{ pt: 1.75 }}>
                                        <DataGrid<IPublicProfile['statistics']['species'][0]>
                                            disableColumnMenu
                                            getRowId={(row) => row.taxonId}
                                            rows={species}
                                            pageSizeOptions={[10]}
                                            initialState={{ pagination: { paginationModel: { pageSize: 15 } } }}
                                            autoHeight
                                            density="compact"
                                            disableRowSelectionOnClick
                                            getRowClassName={(params) => {
                                                return params.row.listItemId ? '' : 'hidden';
                                            }}
                                            onRowClick={(params) => {
                                                if (isMobile || !params.row.listItemId) return;

                                                window.open(`${window.location.origin}/item/${params.row.listItemId}`);
                                            }}
                                            columns={[
                                                {
                                                    field: 'taxonId',
                                                    type: 'string',
                                                    headerName: 'Druh',
                                                    flex: 2,
                                                    minWidth: 170,
                                                    sortable: false,
                                                    valueFormatter: (value, row) => {
                                                        if (!state.taxonsDict) return 'Loading...';

                                                        return (
                                                            state.taxonsDict.find((t) => t.id === row.taxonId)?.name
                                                                .cs || 'Unknown species'
                                                        );
                                                    },
                                                    renderCell: (params) => (
                                                        <Box className="MuiDataGrid-cellContent">
                                                            {!state.taxonsDict && 'Loading...'}
                                                            {(state.taxonsDict &&
                                                                state.taxonsDict.find(
                                                                    (t) => t.id === params.row.taxonId,
                                                                )?.name.cs) ||
                                                                'neznámý druh'}
                                                        </Box>
                                                    ),
                                                } as GridColDef<IPublicProfile['statistics']['species'][0], string>,
                                                {
                                                    field: 'date',
                                                    headerName: 'Datum',
                                                    flex: 1,
                                                    minWidth: 100,
                                                    sortable: false,
                                                    valueFormatter: (value, row) => format(row.date, 'd. M. yyyy'),
                                                } as GridColDef<IPublicProfile['statistics']['species'][0], string>,
                                                {
                                                    field: 'territorialUnitId',
                                                    headerName: 'Místo',
                                                    flex: 2,
                                                    minWidth: 170,
                                                    sortable: false,
                                                    renderCell: (params) => (
                                                        <>
                                                            {params.value && (
                                                                <Box className="MuiDataGrid-cellContent">
                                                                    {!state.placesDict && 'Loading...'}
                                                                    {state.placesDict &&
                                                                    params.value !== undefined &&
                                                                    params.value !== null
                                                                        ? state.placesDict.find(
                                                                              (t) => t.id === params.value,
                                                                          )?.name + ' '
                                                                        : ''}
                                                                </Box>
                                                            )}
                                                            {!params.value && (
                                                                <Typography
                                                                    component="div"
                                                                    className="MuiDataGrid-cellContent"
                                                                    fontStyle="italic"
                                                                >
                                                                    místo skryto
                                                                </Typography>
                                                            )}
                                                            {(!params.value ||
                                                                params.row.secrecyLevel ||
                                                                !params.row.listItemId) && (
                                                                <SecrecyLock
                                                                    secrecyLevel={params.row.secrecyLevel ?? 100}
                                                                    TooltipProps={{
                                                                        title: params.row.listItemId
                                                                            ? undefined
                                                                            : 'Toto pozorování je kompletně skryté.',
                                                                        placement: 'right',
                                                                    }}
                                                                />
                                                            )}
                                                        </>
                                                    ),
                                                },
                                                {
                                                    field: 'actions',
                                                    headerName: '',
                                                    flex: 0.2,
                                                    sortable: false,
                                                    renderCell: (params) => (
                                                        <TableIcons itemId={params.row.listItemId ?? undefined} />
                                                    ),
                                                },
                                            ]}
                                            sx={{
                                                '& .MuiDataGrid-row': {
                                                    '&:not(.hidden)': {
                                                        cursor: 'pointer',
                                                    },
                                                    '&.rarity-remarkable': {
                                                        backgroundColor: '#f3f5f6',
                                                    },
                                                    '&.rarity-uncommon': { backgroundColor: '#dae5ef' },
                                                    '&.rarity-rare': { backgroundColor: '#f8bc88' },
                                                    '&.rarity-remarkable, &.rarity-uncommon, &.rarity-rare': {
                                                        '& .MuiDataGrid-cell.taxonId': {
                                                            fontWeight: 'bold',
                                                        },
                                                    },
                                                },
                                                '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus, \
                                                & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within, \
                                                & .MuiDataGrid-cell:active, & .MuiDataGrid-columnHeader:active': {
                                                    outline: 'none',
                                                },
                                                '& .MuiDataGrid-cell': {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                },
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {!yearsSummary && !years && !species && !lastMedia && (
                            <Box sx={{ bgcolor: 'primary.300', p: 3.25, mb: 2.25, textAlign: 'center' }}>
                                <Typography
                                    variant="subtitle1"
                                    component="h2"
                                    color="white"
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <VisibilityOffRounded fontSize="small" />
                                    &nbsp; Uživatel skryl své statistiky.
                                </Typography>
                            </Box>
                        )}
                        {!!yearsSummary && (
                            <Box sx={{ bgcolor: 'primary.300', p: 2.25, mb: 2.25, textAlign: 'center' }}>
                                <Grid container spacing={2.25}>
                                    <Grid item xs={6} md={2.5}>
                                        <Typography variant="h2" component="dd" color="white">
                                            {yearsSummary.speciesCount}
                                        </Typography>
                                        <Typography variant="body1" component="dt" color="white">
                                            druhů
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={2.5}>
                                        <Typography variant="h2" component="dd" color="white">
                                            {yearsSummary.listItemCount}
                                        </Typography>
                                        <Typography variant="body1" component="dt" color="white">
                                            pozorování
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={2.5}>
                                        <Typography variant="h2" component="dd" color="white">
                                            {yearsSummary.listCount}
                                        </Typography>
                                        <Typography variant="body1" component="dt" color="white">
                                            vycházek
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={4.5} sx={{ textAlign: 'left' }}>
                                        <Typography variant="h2" component="dd" color="white">
                                            {round((yearsSummary.listCompleteCount / yearsSummary.listCount) * 100)} %
                                        </Typography>
                                        <Typography variant="body1" component="dt" color="white">
                                            kompl. seznamů
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                        {!!years && !!years.length && (
                            <TableContainer sx={{ maxHeight: '250px', overflow: 'auto' }}>
                                <Table
                                    size="small"
                                    sx={{
                                        '& .MuiTableCell-head': {
                                            lineHeight: 'normal',
                                            fontWeight: 700,
                                        },
                                        '& .MuiTableCell-root': {
                                            border: '1px solid',
                                            borderColor: 'divider',
                                            paddingTop: '8px',
                                            paddingBottom: '8px',
                                        },
                                    }}
                                >
                                    <TableHead>
                                        <TableRow sx={{ bgcolor: 'action.selected' }}>
                                            <TableCell width="22.5%">Rok</TableCell>
                                            <TableCell width="22.5%">Počet druhů</TableCell>
                                            <TableCell width="22.5%">Počet pozorování</TableCell>
                                            <TableCell>
                                                Počet vycházek
                                                <br />
                                                <Box component="span" sx={{ fontWeight: 400 }}>
                                                    (% kompl. seznamů)
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {years.map((statistic) => (
                                            <TableRow key={statistic.year}>
                                                <TableCell sx={{ bgcolor: 'action.selected' }}>
                                                    {statistic.year}
                                                </TableCell>
                                                <TableCell>{statistic.speciesCount}</TableCell>
                                                <TableCell>{statistic.listItemCount}</TableCell>
                                                <TableCell>
                                                    {statistic.listCount} (
                                                    {Math.round(
                                                        (statistic.listCompleteCount / statistic.listCount) * 100,
                                                    )}{' '}
                                                    %)
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        {!!lastMedia && !!lastMedia.length && (
                            <>
                                <Typography variant="subtitle1" component="h2" sx={{ py: 1.75 }}>
                                    Fotografie posledních pozorování
                                </Typography>
                                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 1200: 3 }}>
                                    <Masonry gutter="1.5rem">
                                        {lastMedia.map((item, index) => (
                                            <MasonryImage
                                                image={{ ...item, note: null }}
                                                key={item.id}
                                                sx={{ maxWidth: '100%' }}
                                                disableDialog
                                                onClick={() =>
                                                    setState((state) => ({
                                                        ...state,
                                                        lightboxOpenOnPicture: index,
                                                    }))
                                                }
                                                onObservationClick={() => {
                                                    window.open(`${window.location.origin}/item/${item.listItemId}`);
                                                }}
                                                transparentCaption
                                            />
                                        ))}
                                    </Masonry>
                                </ResponsiveMasonry>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                    <Link to={galleryLink}>
                                        <Button variant="contained" color="secondary" sx={{ mt: 3 }}>
                                            Otevřít galerii uživatele
                                        </Button>
                                    </Link>
                                </Box>
                                <Lightbox
                                    images={lastMedia}
                                    open={state.lightboxOpenOnPicture !== undefined}
                                    openOnImage={state.lightboxOpenOnPicture}
                                    onClose={() =>
                                        setState((state) => ({ ...state, lightboxOpenOnPicture: undefined }))
                                    }
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};
