import { FC, useEffect, useState } from 'react';
import { IAge, IObservationMapped, ISex, ITaxon } from '../../../../../schemas/interfaces';
import { allDictionaryProvider, taxonsDictionaryProvider } from '../../../../../services/dictProviders';
import { Grid, Typography, Divider, Tooltip } from '@mui/material';
import { countFormatter } from '../../../../../services/dataFormatters';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import SecrecyLock from '../../../../../components/dicts/SecrecyLock';

const ObservationSummaryCard: FC<{ observation: IObservationMapped }> = (props) => {
    const [state, setState] = useState<{
        taxonsDict?: ITaxon[];
        ageDict?: IAge[];
        sexDict?: ISex[];
    }>({});

    const { taxonId, count, sexId, ageId, date, itemId, secrecyLevel } = props.observation;

    useEffect(() => {
        taxonsDictionaryProvider
            .onFirstValue()
            .then((response) => setState((state) => ({ ...state, taxonsDict: response })));
        allDictionaryProvider.onFirstValue().then((response) =>
            setState((state) => ({
                ...state,
                ageDict: response.age,
                sexDict: response.sex,
            })),
        );
    }, []);

    const taxon = state.taxonsDict ? state.taxonsDict.find((taxon) => taxon.id === taxonId) : undefined;

    return (
        <Grid
            container
            component="dl"
            spacing={0.5}
            sx={{ '& div.MuiGrid-root:last-child': { display: 'none' }, marginBottom: 0 }}
            className="ObservationSummaryCard"
        >
            <Grid item xs={12} component="dt">
                <Typography component="span" variant="body2">
                    <Link to={`/item/${itemId}`} target="_blank">
                        {!taxon ? (
                            'Loading...'
                        ) : (
                            <>
                                {taxon.name.cs} - <em>{taxon.name.la}</em>
                            </>
                        )}
                    </Link>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={4} component="dt">
                <Typography component="span" variant="caption" fontWeight="500">
                    Počet
                </Typography>
            </Grid>
            <Grid item xs={8} component="dd">
                <Typography component="span" variant="caption">
                    {countFormatter(
                        sexId || undefined,
                        ageId || undefined,
                        count || undefined,
                        state.sexDict,
                        state.ageDict,
                    )}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={4} component="dt">
                <Typography component="span" variant="caption" fontWeight="500">
                    Datum
                </Typography>
            </Grid>
            <Grid item xs={8} component="dd" sx={{ overflow: 'hidden', position: 'relative' }}>
                <Typography component="span" variant="caption">
                    {format(date, 'd.M.yyyy')}
                </Typography>
                <SecrecyLock
                    secrecyLevel={secrecyLevel}
                    secretUntil={props.observation.secretUntil}
                    SvgIconProps={{ sx: { position: 'absolute', right: 0, top: '8px' } }}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
    );
};

export default ObservationSummaryCard;
