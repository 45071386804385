import { FC, useEffect, useState } from 'react';
import { IAge } from '../../schemas/interfaces';
import { allDictionaryProvider } from '../../services/dictProviders';
import UndefinedDictValue from './UndefinedDictValue';

const AgeFromDict: FC<{ ageId?: number | null }> = ({ ageId }) => {
    const [dict, setDict] = useState<IAge[]>();

    useEffect(() => {
        allDictionaryProvider.onFirstValue().then((dict) => setDict(dict.age));
    }, []);

    if (!ageId) return <UndefinedDictValue />;

    if (!dict) return <>Loading...</>;

    const age = dict.find((age) => age.id === ageId);

    if (!age) return <>Unknown age</>;

    return <>{age.name.cs || 'Missing age label'}</>;
};

export default AgeFromDict;
