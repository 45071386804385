import { FC, useState, useEffect } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import { Alert, Chip, Tooltip, Grid, Button, Typography, Box } from '@mui/material';
import PoppedControl from '../../../../components/formControls/PoppedControl';
import { IProject } from '../../../../schemas/interfaces';
import { ObservationItemFormValues } from '../../ObservationFormApp';
import ProjectFieldInput from './ProjectFieldInput';
import * as z from 'zod';
import jsonSchemaToZod, { JsonSchema } from 'json-schema-to-zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import Loading from '../../../../components/Loading';
import { CheckCircleRounded, ErrorRounded, HelpRounded } from '@mui/icons-material';

export interface ItemProjectFormProps {
    project: IProject;
}

export type ItemProjectFormValues = NonNullable<ObservationItemFormValues['_ProjectsData']>[''];

const ItemProjectForm: FC<ItemProjectFormProps> = ({ project }) => {
    const { values: itemFormValues, setFieldValue } = useFormikContext<ObservationItemFormValues>();
    const [validationSchema, setValidationSchema] = useState<z.ZodTypeAny>();
    const [projectFormData, setProjectFormData] = useState<ItemProjectFormValues>();

    // constructs validation schema from project item fields
    // every field with a type is considered (the other are just required basic fields)
    // all values are considered as strings
    // if a field has a regex, it is used to validate the value
    useEffect(() => {
        const fields = project.itemFields
            .filter((field) => !!field.type)
            .reduce((acc, field) => {
                const schema = field.regex ? z.string().regex(new RegExp(field.regex)) : z.string();
                return {
                    ...acc,
                    [field.questionId]: field.required ? schema : schema.nullish(),
                };
            }, {} as { [key: string]: z.ZodTypeAny });

        setValidationSchema(z.object({ data: z.object(fields).nullish() }));
    }, [project]);

    // loads project data from form values
    // the project data are stored in the item form as an array of objects but for the purposes
    // of this form we need to transform them into an object with keys as question ids
    // also the item form may contain multiple projects so we need to find the right one
    useEffect(() => {
        if (!itemFormValues.projectsData) return;

        // find the project data related to this project
        const thisProjectData = itemFormValues.projectsData?.find((p) => p.projectId === project.id);

        if (!thisProjectData) return;

        setProjectFormData({
            ...thisProjectData,
            // transform project data from array to object with keys as question ids
            data: thisProjectData.data.reduce((acc, field) => {
                return {
                    ...acc,
                    [field.questionId]: field.value,
                };
            }, {}),
        });
    }, [project, itemFormValues.projectsData]);

    // checks if any project data are filled
    const isProjectFilled = Object.values(projectFormData?.data ?? {}).filter((v) => v).length > 0;

    // list of error message for basic required fields which will be displayed instead of the form
    const itemFormBrokenRules = project.itemFields
        .filter(
            (f) =>
                f.type === undefined &&
                f.required &&
                !itemFormValues?.[f.questionId as keyof ObservationItemFormValues],
        )
        .map(
            (f) =>
                f.errorMessage?.cs ??
                `Pro zadání údajů do projektu "${project.name.cs}" je povinné vyplnit pole ${f.name?.cs}.`,
        );

    return (
        <>
            <PoppedControl
                renderIcon={(handleClose) => {
                    return (
                        <Tooltip
                            title={
                                isProjectFilled
                                    ? 'Údaje k projektu jsou úspěšně zaznamenány.'
                                    : project.entryQuestion?.cs
                            }
                        >
                            <Chip
                                label={project.name.cs}
                                size="small"
                                variant="outlined"
                                icon={
                                    itemFormBrokenRules.length > 0 ? (
                                        <ErrorRounded />
                                    ) : isProjectFilled ? (
                                        <CheckCircleRounded />
                                    ) : (
                                        <HelpRounded />
                                    )
                                }
                                color={
                                    itemFormBrokenRules.length > 0 ? 'default' : isProjectFilled ? 'success' : 'primary'
                                }
                                clickable
                            />
                        </Tooltip>
                    );
                }}
                renderControl={(handleClose) => {
                    if (!validationSchema) return <Loading />;

                    if (itemFormBrokenRules.length > 0)
                        return (
                            <Box sx={{ pt: 2 }}>
                                {itemFormBrokenRules.map((error, index) => {
                                    return (
                                        <Alert severity="warning" sx={{ my: 1 }} key={index}>
                                            <Typography variant="body1" component="p">
                                                {error}
                                            </Typography>
                                        </Alert>
                                    );
                                })}
                            </Box>
                        );

                    return (
                        <Formik<ItemProjectFormValues>
                            initialValues={{
                                projectId: project.id,
                                data: {
                                    ...project.itemFields
                                        .filter((field) => !!field.type)
                                        .reduce((acc, field) => {
                                            return {
                                                ...acc,
                                                [field.questionId]: null,
                                            };
                                        }, {}),
                                    ...projectFormData?.data,
                                },
                            }}
                            validationSchema={validationSchema && toFormikValidationSchema(validationSchema)}
                            enableReinitialize
                            onSubmit={(values) => {
                                const thisProjectIndex = itemFormValues.projectsData?.findIndex(
                                    (p) => p.projectId === project.id,
                                );

                                const projectObject = {
                                    projectId: project.id,
                                    data: Object.keys(values.data)
                                        .map((key) => ({
                                            questionId: key,
                                            value: values.data[key],
                                        }))
                                        .filter((f) => f.value),
                                };

                                if (thisProjectIndex !== undefined && thisProjectIndex >= 0)
                                    setFieldValue(`projectsData.${thisProjectIndex}`, projectObject);
                                else
                                    setFieldValue('projectsData', [
                                        ...(itemFormValues.projectsData ?? []),
                                        projectObject,
                                    ]);

                                handleClose();
                            }}
                        >
                            {({ errors, values, touched }) => (
                                <Form noValidate>
                                    <Grid container spacing={2} sx={{ pt: 3 }}>
                                        {(project.itemFields || [])
                                            .filter((f) => f.type)
                                            .map((question, index) => {
                                                return (
                                                    <Grid item xs={12} key={index}>
                                                        <ProjectFieldInput
                                                            key={index}
                                                            question={question}
                                                            projectId={project.id}
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                        {/* <Grid item xs={12}>
                                            <code>{JSON.stringify(itemFormBrokenRules)}</code>
                                            <br />
                                            <code>{JSON.stringify(errors)}</code>
                                            <br />
                                            <code>{JSON.stringify(values)}</code>
                                            <br />
                                            <code>{JSON.stringify(touched)}</code>
                                        </Grid> */}
                                        <Grid item xs={12} sx={{ gap: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button onClick={() => handleClose()}>Zrušit</Button>
                                            <Button type="submit" variant="contained" color="secondary">
                                                Uložit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    );
                }}
                title={project.name.cs ?? ''}
                description={project.description.cs ?? ''}
                disableClickaway={itemFormBrokenRules.length < 1}
                cancelButtonLabel="Zrušit"
                confirmButtonLabel="Uložit"
                confirmButtonDisabled
                disableButtons={itemFormBrokenRules.length < 1}
            />
        </>
    );
};

export default ItemProjectForm;
