import * as z from 'zod';
import {
    schema_activity,
    schema_age,
    schema_filter,
    schema_filterParameters,
    schema_list,
    schema_media,
    schema_observation,
    schema_sort,
    schema_project,
    schema_rarity_attr,
    schema_secrecy,
    schema_sex,
    schema_tags,
    schema_taxon,
    schema_territorialUnit,
    schema_location,
    schema_listItem,
    schema_comment,
    response_list_id,
    schema_projectData,
    schema_newsArticle,
    schema_htmlArticle,
    schema_newsArticleSummary,
    response_user_profile_id,
    request_observations_export,
    request_user_message_id,
    request_list,
    schema_listItem_input,
    schema_list_input,
    schema_coordinates,
    schema_projectInfo,
    request_analytics_countDate,
    schema_chartData,
    response_user,
    schema_area,
    schema_detection,
} from './schemas';

export const schema_observation_table = z.object({
    itemId: schema_observation.shape['itemId'].unwrap().unwrap(),
    listPublicId: schema_observation.shape['listPublicId'].unwrap().unwrap(),
    taxonId: schema_observation.shape['taxonId'],
    date: schema_observation.shape['date'].unwrap().unwrap(),
    count: schema_observation.shape['count'].unwrap().unwrap().nullable(),
    ageId: schema_observation.shape['ageId'].unwrap().unwrap().nullable(),
    sexId: schema_observation.shape['sexId'].unwrap().unwrap().nullable(),
    observersString: schema_observation.shape['observersString'].unwrap().unwrap().nullable(),
    note: schema_observation.shape['note'].unwrap().unwrap().nullable(),
    commentsCount: schema_observation.shape['commentsCount'].unwrap().unwrap().nullable(),
    lastCommented: schema_observation.shape['lastCommented'].unwrap().unwrap().nullable(),
    rarity: schema_observation.shape['rarity'].unwrap().unwrap(),
    tags: schema_observation.shape['tags'].unwrap().unwrap().nullable(),
    siteName: schema_observation.shape['siteName'].unwrap().unwrap().nullable(),
    location: schema_location.required({
        territorialUnitId: true,
    }),
    media: schema_media
        .required({
            url: true,
            file: true,
        })
        .array()
        .optional(),
    projectsString: schema_observation.shape['projectsString'],
    secrecyLevel: schema_observation.shape['secrecyLevel'].unwrap().unwrap().nullable(),
    secretUntil: schema_observation.shape['secretUntil'].unwrap().unwrap().nullable(),
});

const t = schema_observation.shape['count'].unwrap().unwrap().nullable();

export const schema_observation_mapped = schema_observation.extend({
    itemId: schema_observation.shape['itemId'].unwrap().unwrap(),
    taxonId: schema_observation.shape['taxonId'],
    date: schema_observation.shape['date'].unwrap().unwrap(),
    count: schema_observation.shape['count'].unwrap().unwrap().nullable(),
    listPublicId: schema_observation.shape['listPublicId'].unwrap().unwrap(),
    commentsCount: schema_observation.shape['commentsCount'].unwrap().unwrap(),
    location: schema_location.extend({ coordinates: schema_coordinates }),
});

export const schema_observation_gallery = schema_observation_table.extend({
    itemId: schema_observation.shape['itemId'].unwrap().unwrap(),
    taxonId: schema_observation.shape['taxonId'],
    listPublicId: schema_observation.shape['listPublicId'].unwrap().unwrap(),
    date: schema_observation.shape['date'].unwrap().unwrap(),
    observersString: schema_observation.shape['observersString'].unwrap().unwrap(),
});

export const schema_list_listed = schema_list.omit({ items: true }).extend({
    media: schema_media
        .required({
            url: true,
            file: true,
        })
        .array()
        .optional(),
});

export const schema_list_track = schema_list_input.shape['track'].unwrap().unwrap();

export const schema_export_form = request_observations_export
    .omit({
        mode: true,
        language: true,
        fields: true,
        filter: true,
    })
    .extend({
        consent: z.boolean().refine((v) => v === true, 'errors.export.consent.required'),
    });

export const schema_chartsFilter = request_analytics_countDate;

export type ITaxon = z.infer<typeof schema_taxon>;
export type IMedia = z.infer<typeof schema_media>;
export type IObservationListed = z.infer<typeof schema_observation_table>;
export type IObservationMapped = z.infer<typeof schema_observation_mapped>;
export type IObservationGallery = z.infer<typeof schema_observation_gallery>;
export type IListListed = z.infer<typeof schema_list_listed>;
export type IList = z.infer<typeof response_list_id>;
export type IListCreate = z.infer<typeof schema_list_input>;
export type IListItem = z.infer<typeof schema_listItem>;
export type IListItemProject = z.infer<typeof schema_projectData>;
export type IListItemCreate = z.infer<typeof schema_listItem_input>;
export type IComment = z.infer<typeof schema_comment>;
export type IObservationFilter = z.infer<typeof schema_filterParameters>;
export type IAge = z.infer<typeof schema_age>;
export type ISex = z.infer<typeof schema_sex>;
export type IActivity = z.infer<typeof schema_activity>;
export type ITag = z.infer<typeof schema_tags>;
export type ISecrecy = z.infer<typeof schema_secrecy>;
export type IRarity = z.infer<typeof schema_rarity_attr>;
export type IProject = z.infer<typeof schema_project>;
export type IPlace = z.infer<typeof schema_territorialUnit>;
export type IPredefinedFilter = z.infer<typeof schema_filter>;
export type ISort = z.infer<typeof schema_sort>;
export type INewsArticle = z.infer<typeof schema_newsArticle>;
export type INewsArticleSummary = z.infer<typeof schema_newsArticleSummary>;
export type IHelpSection = z.infer<typeof schema_htmlArticle>;
export type IFaqSection = z.infer<typeof schema_htmlArticle>;
export type IAboutSection = z.infer<typeof schema_htmlArticle>;
export type IProjectInfo = z.infer<typeof schema_projectInfo>;
export type IPublicProfile = z.infer<typeof response_user_profile_id>;
export type IUserMessage = z.infer<typeof request_user_message_id>;
export type IUser = z.infer<typeof response_user>;
export type ITrack = z.infer<typeof schema_list_track>;
export type ICoordinates = ITrack[0];
export type IExport = z.infer<typeof schema_export_form>;
export type IChartsFilter = z.infer<typeof request_analytics_countDate>;
export type IChartData = z.infer<typeof schema_chartData>;
export type IArea = z.infer<typeof schema_area>;
export type IDetection = z.infer<typeof schema_detection>;
