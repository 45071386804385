import IObsListsResultsComponentProps from '../../../../interfaces/IObsListsResultsComponentProps';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useEffect, useState } from 'react';
import { useApi } from '../../../../services/apiContext';
import { Box, Button, SpeedDial, SpeedDialAction, Typography } from '@mui/material';
import { Settings, TranslateTwoTone, Visibility, VisibilityOff } from '@mui/icons-material';
import MasonryImage from '../../../../components/gallery/MasonryImage';
import { IMedia, IObservationFilter, ITaxon } from '../../../../schemas/interfaces';
import Lightbox from '../../../../components/gallery/Lightbox';
import { useNavigate } from 'react-router-dom';
import { taxonsDictionaryProvider } from '../../../../services/dictProviders';
import { format } from 'date-fns';
import { useAuth } from '../../../../services/authenticator';
import useDeepCompareEffect from 'use-deep-compare-effect';
import LongLoadingOverlay from '../../../../components/dataGrid/LongLoadingOverlay';
import SpeciesLanguageSwitcher from '../../../../components/formControls/SpeciesLanguageSwitcher';
import { useTaxonLanguage } from '../../../../services/taxonLanguageContext';
import TaxonFromDict from '../../../../components/dicts/TaxonFromDict';

const GALLERY_PAGE_SIZE = 20;

interface ObsItemsGalleryState {
    galleryItems?: IGalleryItem[];
    page: number;
    loading: boolean;
    total?: number;
    lightboxOpenOnPicture?: number;
    taxonsDict?: ITaxon[];
    taxonName: 'local' | 'latin';
    taxonNameVisible: boolean;
}

interface IGalleryItem {
    image: IMedia;
    itemId: number;
    listId: string;
    taxonId: number;
    date: Date;
    observer?: string | null;
}

const ObsItemsGallery: React.FunctionComponent<Required<IObsListsResultsComponentProps>> = (props) => {
    const [state, setState] = useState<ObsItemsGalleryState>({
        page: 1,
        loading: false,
        taxonName: 'local',
        taxonNameVisible: true,
    });
    const api = useApi();
    const { isLoggedIn } = useAuth();
    const { currentLanguage } = useTaxonLanguage();

    useEffect(() => {
        taxonsDictionaryProvider
            .onFirstValue()
            .then((taxons) => setState((state) => ({ ...state, taxonsDict: taxons })));
    }, []);

    useDeepCompareEffect(() => {
        if (!props.query || isLoggedIn === undefined) return;

        setState((state) => ({ ...state, loading: true, galleryItems: undefined }));

        fetchMedia(1, props.query).then((media) => {
            console.log(media.length, GALLERY_PAGE_SIZE);
            setState((state) => ({
                ...state,
                galleryItems: media,
                loading: false,
                total: media.length < GALLERY_PAGE_SIZE ? media.length : 99999,
            }));
        });
    }, [props.query, isLoggedIn]);

    useEffect(() => {
        if (state.page === 1) return;

        setState((state) => ({ ...state, loading: true }));

        fetchMedia(state.page, props.query).then((media) => {
            setState((state) => ({
                ...state,
                galleryItems: [...(state.galleryItems || []), ...media],
                loading: false,
                total: media.length < GALLERY_PAGE_SIZE ? state.galleryItems?.length : 99999,
            }));
        });
    }, [state.page]);

    const fetchMedia = (page: number, query: IObservationFilter) => {
        return api
            .getObservationsGallery({
                page,
                pageSize: GALLERY_PAGE_SIZE,
                filter: query,
                fields: null,
                sort: [{ field: 'date', order: 'desc', language: 'cs' }],
                type: ['photo'],
            })
            .then((response) => {
                const newGalleryItems = response.items.reduce((acc: IGalleryItem[], item) => {
                    return acc.concat(
                        (item.media || []).map((image) => ({
                            image,
                            itemId: item.itemId,
                            listId: item.listPublicId,
                            taxonId: item.taxonId,
                            date: item.date,
                            observer: item.observersString,
                        })),
                    );
                }, [] as IGalleryItem[]);

                return newGalleryItems;
            })
            .catch((e) => {
                setState((state) => ({ ...state, loading: false }));
                props.onFetchError && props.onFetchError(e);
                return [];
            });
    };

    if (state.galleryItems === undefined) return <LongLoadingOverlay />;

    if (state.galleryItems.length < 1)
        return (
            <Box className="ObsItemsGallery" sx={{ px: 2.25, py: 4 }}>
                <Typography variant="subtitle1" component="h2" textAlign="center">
                    Kritériím vyhledávání neodpovídají žádné fotografie.
                </Typography>
            </Box>
        );

    const makeCaption = (item: IGalleryItem) => (
        <>
            <TaxonFromDict taxonId={item.taxonId} boxProps={{ sx: { display: 'inline-flex' } }} />,{' '}
            {format(item.date, 'd. M. yyyy')}
        </>
    );

    return (
        <Box className="ObsItemsGallery" sx={{ px: 2.25, py: 4 }}>
            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1200: 4 }}>
                <Masonry gutter="1.5rem">
                    {state.galleryItems.map((item, index) => (
                        <MasonryImage
                            caption={makeCaption(item)}
                            image={item.image}
                            key={item.image.id}
                            disableDialog
                            fixedAspectRatio
                            onClick={() =>
                                setState((state) => ({
                                    ...state,
                                    lightboxOpenOnPicture: index,
                                }))
                            }
                            onObservationClick={() => {
                                window.open(`${window.location.origin}/item/${item.itemId}`);
                            }}
                            onListClick={() => {
                                window.open(`${window.location.origin}/list/${item.listId}`);
                            }}
                            taxonName={state.taxonName}
                        />
                    ))}
                </Masonry>
            </ResponsiveMasonry>
            {state.loading && <LongLoadingOverlay boxSx={{ paddingTop: 4 }} />}
            {!state.loading && state.total && state.galleryItems.length < state.total && (
                <Button
                    onClick={() => {
                        if (!state.total || !state.galleryItems || state.galleryItems.length >= state.total) return;

                        setState((state) => ({ ...state, page: state.page + 1 }));
                    }}
                    variant="contained"
                    color="secondary"
                    sx={{ margin: '3rem auto 0', display: 'block' }}
                >
                    Načíst další fotografie
                </Button>
            )}
            <Lightbox
                captions={state.galleryItems.map((item) => makeCaption(item))}
                images={state.galleryItems.map((item) => item.image)}
                open={state.lightboxOpenOnPicture !== undefined}
                openOnImage={state.lightboxOpenOnPicture}
                onClose={() => setState((state) => ({ ...state, lightboxOpenOnPicture: undefined }))}
            />
            <SpeedDial
                className="tour-search-7"
                ariaLabel="Nastavení galerie"
                sx={{
                    position: 'fixed',
                    bottom: '2rem',
                    right: 'calc(1rem + 3.5rem + 2rem)',
                    '.MuiSvgIcon-root': {
                        transition: 'transform 0.5s',
                    },
                    '&:hover': {
                        '& > .MuiButtonBase-root > .MuiSvgIcon-root': {
                            transform: 'rotate(90deg)',
                        },
                    },
                }}
                icon={<Settings />}
            >
                {state.taxonNameVisible && (
                    <SpeedDialAction
                        icon={<SpeciesLanguageSwitcher tooltipProps={{ placement: 'left' }} />}
                        tooltipTitle=""
                    />
                )}
                {state.taxonNameVisible && (
                    <SpeedDialAction
                        icon={<VisibilityOff />}
                        tooltipTitle="Skrýt názvy druhů"
                        onClick={() => setState((state) => ({ ...state, taxonNameVisible: false }))}
                    />
                )}
                {!state.taxonNameVisible && (
                    <SpeedDialAction
                        icon={<Visibility />}
                        tooltipTitle="Zobrazit názvy druhů"
                        onClick={() => setState((state) => ({ ...state, taxonNameVisible: true }))}
                    />
                )}
            </SpeedDial>
        </Box>
    );
};

export default ObsItemsGallery;
