import { FC, useEffect, useState } from 'react';
import { IPlace } from '../../schemas/interfaces';
import { placesDictionaryProvider } from '../../services/dictProviders';
import UndefinedDictValue from './UndefinedDictValue';
import { locationFormatter } from '../../services/dataFormatters';

const PlaceFromDict: FC<{ siteName?: string | null; placeId?: number | null }> = ({ siteName, placeId }) => {
    const [dict, setDict] = useState<IPlace[]>();

    useEffect(() => {
        placesDictionaryProvider.onFirstValue().then((response) => setDict(response.items));
    }, []);

    if (!placeId) return <UndefinedDictValue />;

    if (!dict) return <>Loading...</>;

    return <>{locationFormatter(siteName, placeId, dict)}</>;
};

export default PlaceFromDict;
