import { FC, useEffect, useRef, useState } from 'react';
import html2canvas, { Options as Html2CanvasOptions } from 'html2canvas';
import { Box, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { CameraAlt, Print } from '@mui/icons-material';
import { useMap } from 'react-leaflet';
import { DomEvent } from 'leaflet';
import Loading from '../Loading';

const MapToImage: FC<Record<string, never>> = (props) => {
    const map = useMap();
    const exportRef = useRef<HTMLButtonElement>(null);
    const printRef = useRef<HTMLButtonElement>(null);
    const [isPrinting, setIsPrinting] = useState<boolean>(false);
    const isMobile = useMediaQuery('(pointer: coarse)');

    const map2image = (options?: Partial<Html2CanvasOptions>) => {
        return new Promise<Blob>((resolve, reject) => {
            if (!map) return reject(new Error('Map is not initialized'));

            html2canvas(map.getContainer(), {
                useCORS: true,
                allowTaint: false,
                scale: 2,
                ignoreElements: (element) => element.classList.contains('leaflet-control'),
                ...options,
            })
                .then((canvas) => {
                    canvas.toBlob((blob) => {
                        if (!blob) {
                            reject(new Error('Failed to create blob'));
                            return;
                        }
                        resolve(blob);
                    });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const handleExport = () => {
        map2image().then((blob: Blob) => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'mapa.png';
            a.click();
        });
    };

    const handlePrint = () => {
        if (!map) return;

        setIsPrinting(true);

        // store original map state, so we can restore it after printing
        const originalHeight = map.getContainer().style.height;
        const originalCenter = map.getCenter();

        // temporarily increase map size for correct aspect ratio
        const tempHeight = map.getContainer().offsetWidth * 0.67;
        map.getContainer().style.height = tempHeight + 'px';
        map.invalidateSize();
        map.setView(originalCenter);

        setTimeout(() => {
            map2image({ height: tempHeight })
                .then((blob: Blob) => {
                    const url = URL.createObjectURL(blob);
                    const img = document.createElement('img');
                    img.src = url;
                    img.onload = () => {
                        const w = window.open();
                        w?.document.write('<img src="' + img.src + '" style="width: 100vw;"/>');
                        w?.print();
                    };
                })
                .finally(() => {
                    // restore original map state
                    map.getContainer().style.height = originalHeight;
                    map.invalidateSize();
                    map.setView(originalCenter);

                    setIsPrinting(false);
                });
        }, 300);
    };

    useEffect(() => {
        if (exportRef.current) DomEvent.disableClickPropagation(exportRef.current);
        if (printRef.current) DomEvent.disableClickPropagation(printRef.current);
    }, [exportRef, printRef]);

    if (isMobile) return null;

    return (
        <>
            <Box className="leaflet-top leaflet-right" sx={{ top: '38px', zIndex: '440 !important' }}>
                <Box
                    className="leaflet-control leaflet-bar"
                    sx={{ display: 'flex', flexDirection: 'column', background: '#fff' }}
                >
                    <Tooltip title="Stáhnout mapu ve formátu PNG" placement="top">
                        <IconButton
                            onClick={handleExport}
                            ref={exportRef}
                            tabIndex={0}
                            size="small"
                            sx={{
                                width: '30px',
                                height: '30x',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '6px',
                                boxSizing: 'border-box',
                                color: 'grey.500',
                                fontSize: '1.2rem',
                            }}
                        >
                            <CameraAlt fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Box className="leaflet-top leaflet-right" sx={{ top: '77px', zIndex: '440 !important' }}>
                <Box
                    className="leaflet-control leaflet-bar"
                    sx={{ display: 'flex', flexDirection: 'column', background: '#fff' }}
                >
                    <Tooltip title="Vytisknout mapu" placement="top">
                        <IconButton
                            onClick={handlePrint}
                            ref={printRef}
                            tabIndex={0}
                            size="small"
                            sx={{
                                width: '30px',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '6px',
                                boxSizing: 'border-box',
                                color: 'grey.500',
                                fontSize: '1.2rem',
                            }}
                        >
                            {isPrinting ? (
                                <Loading
                                    inline
                                    boxSx={{
                                        height: '18px',
                                        width: '18px',
                                    }}
                                    progressProps={{ size: '18px', thickness: 6 }}
                                />
                            ) : (
                                <Print fontSize="inherit" />
                            )}
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </>
    );
};

export default MapToImage;
