import { FC, useEffect, useState } from 'react';
import { addYears, parse } from 'date-fns';
import { FormikProps } from 'formik';
import { Typography, TextField, Chip } from '@mui/material';
import PoppedControl from '../../../../components/formControls/PoppedControl';
import { parseCountString } from '../../../../services/parsers';
import { ObservationItemFormValues } from '../../ObservationFormApp';
import ItemRarityIcon from '../../../../components/grid/ItemRarityIcon';
import { capitalize } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { ITaxon, IRarity, IPlace } from '../../../../schemas/interfaces';
import { allDictionaryProvider, taxonsDictionaryProvider, placesDictionaryProvider } from '../../../../services/dictProviders';
import { ObsListDetailsFormValues } from '../ObsListDetailsForm';

const RareSpeciesValidator: FC<{
    listValues: ObsListDetailsFormValues;
    itemValues: ObservationItemFormValues;
    formikProps: FormikProps<ObservationItemFormValues>;
    formId: string;
    hideTags?: boolean;
    submitOnChange?: boolean;
}> = ({ listValues, itemValues, formikProps, formId, hideTags, submitOnChange }) => {
    const [taxonInfo, setTaxonInfo] = useState<ITaxon>();
    const [rarityInfo, setRarityInfo] = useState<IRarity>();
    const [openNotePopup, setOpenNotePopup] = useState<boolean>(false);
    const [popupCancelTaxonId, setPopupCancelTaxonId] = useState<number>();

    const { municipalityPartId, date } = listValues;
    const { setFieldValue, values, errors, touched, handleBlur, submitForm } = formikProps;
    const { count, taxonId } = values;
    const { count: oldCount, taxonId: oldTaxonId } = itemValues;

    // react to change od taxonId in the form values by updating the taxonInfo
    useEffect(() => {
        allDictionaryProvider.onFirstValue().then(({ rarity: rarities }) => {
            taxonsDictionaryProvider.onFirstValue().then((taxons) => {
                placesDictionaryProvider.onFirstValue().then((places) => {
                    const taxon = taxons.find((taxon) => taxon.id === taxonId);

                    // if taxon or municipalityPartId has been not set or removed, reset taxonInfo and rarityInfo
                    if (!taxon || !municipalityPartId) {
                        setTaxonInfo(undefined);
                        setRarityInfo(undefined);
                        return;
                    }

                    // lookup rarirty rules that are relevant to current municipalityPartId and date
                    const findParentPlaces: (placeId: number) => IPlace[] = (placeId) => {
                        const place = places.items.find((p) => p.id === placeId);

                        if (!place) return [];

                        if (!place.parentId) return [place];

                        return [place, ...findParentPlaces(place.parentId)];
                    };
                    const parentPlacesIds = findParentPlaces(municipalityPartId).map((p) => p.id);
                    const rarityRules = (taxon.rarity ?? [])
                        .filter((r) => parentPlacesIds.includes(r.territory))
                        .map((r) => {
                            return r.period
                                .filter((p) => {
                                    const from = parse(p.from, 'MM-dd', date);
                                    const to = parse(p.to, 'MM-dd', date);

                                    // fix for over-the-end-of-year periods
                                    // we need to check both the intervals that start in the previous year
                                    // and end in the current year and the intervals that start in the current year
                                    // and end in the next year
                                    // e.g. if we are adding observation on 30.12.2023 with a rule 12-01 to 01-31,
                                    // we need to check both the interval 12-01-2022 to 01-31-2023 and the interval
                                    // 12-01-2023 to 01-31-2024
                                    if (from > to) {
                                        const fromLastYear = addYears(from, -1);
                                        const toThisYear = to;
                                        const fromThisYear = from;
                                        const toNextYear = addYears(to, 1);

                                        return (
                                            (fromLastYear <= date && date <= toThisYear) ||
                                            (fromThisYear <= date && date <= toNextYear)
                                        );
                                    }

                                    return from <= date && date <= to;
                                })
                                .sort((a, b) => a.min - b.min);
                        })
                        .flat()
                        .sort((a, b) => b.level - a.level);

                    // set the taxonInfo so other effects can react to it
                    setTaxonInfo({ ...taxon, rarity: [{ territory: municipalityPartId, period: rarityRules }] });
                });
            });
        });
    }, [taxonId]);

    //whenever taxonInfo or count changes, check if there is any valid constraint
    useEffect(() => {
        allDictionaryProvider.onFirstValue().then(({ rarity: rarities }) => {
            if (!taxonInfo || taxonId !== taxonInfo.id || !taxonInfo.rarity) return;

            const parsed = parseCountString(count || '1');
            if (!parsed) return;

            const { min, max } = parsed;
            const minCountRules = taxonInfo.rarity[0].period.filter(
                (r) => (min && r.min <= min) || (max && r.min < max),
            );

            if (minCountRules.length < 1) {
                setRarityInfo(undefined);
                console.log('no problems, go ahead');
                setFieldValue('taxonId', taxonId);
                !!submitOnChange && submitForm();
                return;
            }

            // not very clean solution, we need to reset rarityInfo so the effect is triggered again
            // this is used when user changes taxon to one with the same constraint
            setRarityInfo(undefined);
            setTimeout(() => setRarityInfo(rarities.find((r) => r.level === minCountRules[0].level)));
        });
    }, [taxonInfo, count]);

    // trigger popup when taxon changes
    useEffect(() => {
        // ignore all initial changes
        if (!formikProps.dirty) return;
        // ignore when taxonId is not changed
        if (taxonId === oldTaxonId) return;
        // if there is any valid constraint, display popup
        if (rarityInfo) {
            setOpenNotePopup(true);
            // save old taxonId to be able to cancel popup
            setPopupCancelTaxonId(oldTaxonId);
        }
    }, [rarityInfo]);

    // trigger alert when count changes
    useEffect(() => {
        // ignore all initial changes
        if (!formikProps.dirty && !formikProps.touched.count) return;
        // ignore when count is not changed
        if (count === oldCount) return;
        // if there is any valid constraint, display alert
        if (rarityInfo) {
            openSnackbar();
        }
    }, [rarityInfo, count]);

    const rarityCount = taxonInfo?.rarity?.[0]?.period?.[0]?.min;
    const rarityLabel = rarityInfo?.label;
    const rarityLevel = rarityInfo?.level ?? 0;
    const rarityName = rarityInfo?.name?.cs;

    const openSnackbar = () => {
        enqueueSnackbar(
            `Pozorování tohoto druhu je v\xa0zadané oblasti a čase při\xa0počtu vyšším než\xa0${rarityCount}
            považováno za\xa0${rarityName}. Doplňte k\xa0pozorování poznámku.`,
            { variant: 'warning' },
        );
    };

    return (
        <>
            {!!rarityLabel && !hideTags && (
                <Chip
                    size="small"
                    label={capitalize(rarityName) || ''}
                    sx={{
                        pointerEvents: 'none',
                        '.MuiSvgIcon-root': {
                            pl: 1,
                        },
                        bgcolor: 'primary.50',
                    }}
                    icon={
                        <ItemRarityIcon
                            item={{ rarity: rarityLabel as 'rare' | 'common' | 'remarkable' | 'uncommon' }}
                        />
                    }
                />
            )}
            {openNotePopup && !!rarityName && (
                <PoppedControl
                    open
                    renderIcon={() => <></>}
                    renderControl={() => (
                        <>
                            <Typography variant="body1" sx={{ mb: 1 }}>
                                {rarityLevel >= 30 && rarityLevel < 60 && (
                                    <>Prosím, podělte se s&nbsp;námi o&nbsp;to, čím vám pozorování připadá zajímavé.</>
                                )}
                                {rarityLevel >= 60 && rarityLevel < 90 && (
                                    <>
                                        Pozorování tohoto druhu je v&nbsp;zadané oblasti a čase považováno
                                        za&nbsp;neobvyklé. Prosím, přidejte komentář.
                                    </>
                                )}
                                {rarityLevel >= 90 && (
                                    <>
                                        Pozorování tohoto druhu je v&nbsp;zadané oblasti a čase považováno za&nbsp;velmi
                                        vzácné. Prosím, přidejte komentář.
                                    </>
                                )}
                            </Typography>
                            <TextField
                                multiline
                                minRows={3}
                                maxRows={6}
                                value={values.note || ''}
                                name="note"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setFieldValue('note', value || null);
                                }}
                                onBlur={handleBlur}
                                label="Vložte poznámku k&nbsp;pozorování"
                                fullWidth
                                error={!!errors.note && !!touched.note}
                                helperText={!!errors.note && !!touched.note && errors.note}
                                inputProps={{ form: formId }}
                            />
                            {(rarityInfo?.level ?? 0) >= 60 && (
                                <Typography variant="caption" sx={{ mt: 1 }}>
                                    Sesbíraná data se používají pro&nbsp;vědu a ochranu přírody, proto je velice
                                    důležité vzácné druhy plně zdokumentovat. Podělte se s&nbsp;námi prosím o to,
                                    na&nbsp;základě jakých znaků byl pták v&nbsp;terénu určen a po&nbsp;vyplnění
                                    poznámky přidejte i fotografie.
                                </Typography>
                            )}
                        </>
                    )}
                    onConfirmed={async () => {
                        // when confirmed, set taxonId and submit if needed
                        await setFieldValue('taxonId', values.taxonId);
                        if (submitOnChange) {
                            submitForm();
                        }
                    }}
                    onCanceled={() => {
                        // when cancelled, set taxonId back to old value
                        setFieldValue('taxonId', popupCancelTaxonId);
                    }}
                    onPopClose={() => {
                        setOpenNotePopup(false);
                    }}
                    cancelButtonLabel="Zrušit a vybrat jiný druh"
                    confirmButtonLabel="Pokračovat"
                    title={
                        (rarityName.substring(0, 1).toUpperCase() || '') +
                        (rarityName.substring(1) || '') +
                        ' pozorování'
                    }
                    disableClickaway
                />
            )}
        </>
    );
};

export default RareSpeciesValidator;
