import { FunctionComponent } from 'react';
import { IActivity } from '../../schemas/interfaces';
import { allDictionaryProvider } from '../../services/dictProviders';
import DictionarySelect, { DictionarySelectPublicProps } from './DictionarySelect';

/**
 * Implementation of DictionarySelect for activity dictionary.
 */
const ActivitySelect: FunctionComponent<DictionarySelectPublicProps<number, IActivity>> = (props) => {
    return (
        <DictionarySelect<number, IActivity>
            color="secondary"
            {...props}
            getDictOptions={
                new Promise((resolve, reject) => {
                    allDictionaryProvider
                        .onFirstValue()
                        .then((dicts) => {
                            resolve(dicts.activity.sort((a, b) => a.id - b.id));
                        })
                        .catch((err) => {
                            reject(err);
                        });
                })
            }
            getDictValues={(dicts) => dicts.map((dict) => dict.id)}
            getOptionLabel={(option, dicts = [] as IActivity[]) =>
                dicts.find((dict) => dict.id === option)?.name.cs || `Unknown activity ${option}`
            }
            getSubcategoryLevel={(option, dicts) => {
                if (!dicts) return 0;

                const activity = dicts.find((dict) => dict.id === option);

                if (!activity) return 0;

                return activity.parents.length;
            }}
        />
    );
};

export default ActivitySelect;
