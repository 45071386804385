import { FC } from 'react';
import { IProject } from '../../../../schemas/interfaces';
import { ObservationItemFormValues } from '../../ObservationFormApp';
import { useFormikContext } from 'formik';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import NumberField from '../../../../components/formControls/NumberField';
import { ItemProjectFormValues } from './ProjectForm';

interface ProjectFieldInputProps {
    question: IProject['itemFields'][0];
    projectId: IProject['id'];
}

const ProjectFieldInput: FC<ProjectFieldInputProps> = ({ question, projectId }) => {
    const { values, setFieldValue, errors, touched, handleBlur } = useFormikContext<ItemProjectFormValues>();
    const value = values.data?.[question.questionId] ?? null;
    const error = errors.data?.[question.questionId];
    const touchedThis = touched.data?.[question.questionId];

    const name = `data.${question.questionId}`;
    const label = question.name?.cs ?? question.name?.en ?? '';
    const hint = question.hint?.cs ?? question.hint?.en ?? '';

    const setValue = (value: string | number | boolean | null) => {
        value = value === '' ? null : String(value);
        const data = {
            ...values.data,
            [question.questionId]: value,
        };
        const projectDataLength = Object.keys(data).filter(
            (key) => data[key] !== null && data[key] !== undefined,
        ).length;

        setFieldValue(`data`, projectDataLength > 0 ? data : {});
    };

    return (
        <>
            {question.type === 'enum' && (
                <FormControl fullWidth error={touchedThis && !!error} required={question.required}>
                    <InputLabel>{label}</InputLabel>
                    <Select
                        value={value ?? ''}
                        name={name}
                        label={label}
                        onChange={(e) => setValue(e.target.value)}
                        onBlur={handleBlur}
                        fullWidth
                        required={question.required}
                    >
                        <MenuItem value={''}>
                            <em>nezadáno</em>
                        </MenuItem>
                        {question.entries?.map((entry) => (
                            <MenuItem key={entry.value} value={entry.value}>
                                {entry.text.cs ?? entry.text.en}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{touchedThis && error ? question.errorMessage?.cs ?? error : hint}</FormHelperText>
                </FormControl>
            )}
            {['string', 'text'].includes(question.type as string) && (
                <TextField
                    value={value ?? ''}
                    name={name}
                    label={label}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={handleBlur}
                    multiline={question.type === 'text'}
                    rows={question.type === 'text' ? 5 : 1}
                    fullWidth
                    required={question.required}
                    error={touchedThis && !!error}
                    helperText={touchedThis && error ? question.errorMessage?.cs ?? error : hint}
                />
            )}
            {question.type === 'number' && (
                <NumberField
                    value={value ?? ''}
                    name={name}
                    label={label}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={handleBlur}
                    fullWidth
                    required={question.required}
                    error={touchedThis && !!error}
                    helperText={touchedThis && error ? question.errorMessage?.cs ?? error : hint}
                />
            )}
        </>
    );
};

export default ProjectFieldInput;
