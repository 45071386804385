import { FunctionComponent } from 'react';
import { ISex } from '../../schemas/interfaces';
import { allDictionaryProvider } from '../../services/dictProviders';
import DictionarySelect, { DictionarySelectPublicProps } from './DictionarySelect';

/**
 * Implementation of DictionarySelect for sex dictionary.
 */
const SexSelect: FunctionComponent<DictionarySelectPublicProps<number, ISex>> = (props) => {
    return (
        <DictionarySelect<number, ISex>
            color="secondary"
            {...props}
            getDictOptions={
                new Promise((resolve, reject) => {
                    allDictionaryProvider
                        .onFirstValue()
                        .then((dicts) => {
                            resolve(dicts.sex);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                })
            }
            getDictValues={(dicts) => dicts.map((dict) => dict.id)}
            getOptionLabel={(option, dicts = [] as ISex[]) =>
                dicts.find((dict) => dict.id === option)?.name.cs || `Unknown sex ${option}`
            }
        />
    );
};

export default SexSelect;
