import { FC, useEffect, useState } from 'react';
import { ITaxon } from '../../schemas/interfaces';
import { taxonsDictionaryProvider } from '../../services/dictProviders';
import { Box, BoxProps, Typography } from '@mui/material';
import UndefinedDictValue from './UndefinedDictValue';
import { ContactSupportRounded } from '@mui/icons-material';
import { TooltipMobile } from '../Tooltip';
import { Link } from 'react-router-dom';
import { SupportedTaxonLanguages, translateTaxonName, useTaxonLanguage } from '../../services/taxonLanguageContext';

const TaxonFromDict: FC<{
    taxonId?: number | null;
    boxProps?: BoxProps;
    uncertain?: boolean;
    adviceRequested?: boolean;
    link?: string;
}> = ({ taxonId, boxProps, uncertain, adviceRequested, link }) => {
    const [dict, setDict] = useState<ITaxon[]>();
    const [taxon, setTaxon] = useState<ITaxon>();
    const [taxonName, setTaxonName] = useState<string>();
    const { currentLanguage } = useTaxonLanguage();

    useEffect(() => {
        taxonsDictionaryProvider.onFirstValue().then((dict) => setDict(dict));
    }, []);

    useEffect(() => {
        if (!dict) return;

        setTaxon(dict.find((taxon) => taxon.id === taxonId));
    }, [dict, taxonId]);

    useEffect(() => {
        if (!taxon || !currentLanguage) return;

        setTaxonName(translateTaxonName(taxon, currentLanguage));
    }, [taxon, currentLanguage]);

    if (!dict) return <>Loading...</>;

    if (taxonId === null || taxonId === undefined)
        return <UndefinedDictValue label={taxonId === null ? 'neurčen' : undefined} />;

    return (
        <Box
            title={taxonName}
            {...boxProps}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyItems: 'center',
                ...boxProps?.sx,
                fontStyle: currentLanguage === 'la' ? 'italic' : 'normal',
            }}
        >
            {!!uncertain && (
                <TooltipMobile title="Nejisté určení druhu">
                    <Box sx={{ pr: 0.5 }}>?</Box>
                </TooltipMobile>
            )}
            {link ? (
                <Link to={link} style={{ color: 'inherit' }} target="_blank">
                    {taxonName}
                </Link>
            ) : (
                <>{taxonName}</>
            )}
            {!!adviceRequested && (
                <TooltipMobile title="Žádost o&nbsp;pomoc s&nbsp;určením">
                    <ContactSupportRounded fontSize="small" sx={{ ml: 1, color: 'action.active' }} />
                </TooltipMobile>
            )}{' '}
        </Box>
    );
};

export default TaxonFromDict;
