import { FC } from 'react';
import { useDict } from '../../services/dictContext';
import { countFormatter } from '../../services/dataFormatters';

const CountFromDict: FC<{ ageId?: number | null; sexId?: number | null; count?: string | null }> = ({
    ageId,
    sexId,
    count,
}) => {
    const { age, sex } = useDict();

    return <span>{countFormatter(sexId, ageId, count, sex, age)}</span>;
};

export default CountFromDict;
