import { FunctionComponent, useState } from 'react';
import { ITaxon } from '../../schemas/interfaces';
import { taxonsDictionaryProvider } from '../../services/dictProviders';
import DictionaryAutocomplete, { DictionaryAutocompletePublicProps } from './DictionaryAutocomplete';
import { Box, IconButton, InputAdornment, MenuItem, Typography, useMediaQuery } from '@mui/material';
import { Pets } from '@mui/icons-material';
import { TooltipMobile } from '../Tooltip';
import { translateTaxonName, useTaxonLanguage } from '../../services/taxonLanguageContext';
import TaxonLanguageSwitcher from './TaxonLanguageSwitcher';

export interface SpeciesAutocompleteProps extends DictionaryAutocompletePublicProps<ITaxon, number> {
    disableNonBirdSpecies?: boolean;
    enableLanguageSwitch?: boolean;
}

/**
 * Implementation of DictionaryAutocomplete for species dictionary.
 */
const SpeciesAutocomplete: FunctionComponent<SpeciesAutocompleteProps> = (props) => {
    const [birdOnly, setBirdOnly] = useState<boolean>(true);
    const isMobile = useMediaQuery('(pointer: coarse) and (max-width: 768px)');
    const { currentLanguage } = useTaxonLanguage();

    const getLocalLabel = (taxon: ITaxon) => {
        return translateTaxonName(taxon, currentLanguage);
    };

    const getLatinLabel = (taxon: ITaxon) => {
        return taxon.name.la ?? `Unknown taxon ${taxon.id}`;
    };

    const optionsFilter = (taxons: ITaxon[]) => {
        const filtered = birdOnly ? taxons.filter((option) => option.id === 0 || option.id > 254) : taxons;

        return props.optionsFilter ? props.optionsFilter(filtered) : filtered;
    };

    const { disableNonBirdSpecies, enableLanguageSwitch, ...autocompleteProps } = props;

    return (
        <DictionaryAutocomplete<ITaxon, number>
            {...autocompleteProps}
            getDictOptions={
                new Promise((resolve, reject) => {
                    taxonsDictionaryProvider
                        .onFirstValue()
                        .then((taxons) => {
                            const sorted = taxons.sort((a, b) => a.id - b.id);
                            const unknown = sorted.find((taxon) => taxon.id === 0);

                            resolve([unknown as ITaxon, ...sorted.filter((option) => option.id !== 0)]);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                })
            }
            getOptionLabel={(taxon) => {
                return translateTaxonName(taxon, currentLanguage);
            }}
            getOptionById={(id, species) => species.find((specie) => specie.id === id)}
            optionsFilter={optionsFilter}
            stringifyOption={(taxon: ITaxon) => {
                const labelLocal = getLocalLabel(taxon);
                const labelLatin = getLatinLabel(taxon);

                const toShortcut = (label: string) =>
                    label
                        .split(' ')
                        .map((word) => word[0] + word[1])
                        .join('');

                const shortLocal = toShortcut(labelLocal);
                const shortLatin = toShortcut(labelLatin);

                return `${labelLocal}|${labelLocal.replace(' ', '|')}|${shortLocal}|\
                        ${labelLatin}|${labelLatin.replace(' ', '|')}|${shortLatin}`;
            }}
            TextFieldProps={{
                ...props.TextFieldProps,
                InputProps: {
                    ...props.TextFieldProps?.InputProps,
                    endAdornment: (
                        <>
                            <InputAdornment
                                position="end"
                                className="MuiInputAdornment-avifControls"
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    height: 'auto',
                                    right: '50px',
                                    display: isMobile ? 'flex' : 'none',
                                    gap: '10px',
                                }}
                            >
                                {!!enableLanguageSwitch && (
                                    <Box className="MuiInputAdornment-translate">
                                        <TaxonLanguageSwitcher
                                            chipProps={{ sx: { fontSize: { xs: '75%', md: undefined } } }}
                                        />
                                    </Box>
                                )}
                                {!disableNonBirdSpecies && (
                                    <Box className="MuiInputAdornment-species">
                                        <TooltipMobile
                                            title={
                                                birdOnly
                                                    ? 'Vyhledávat i mezi\xa0neptačími druhy'
                                                    : 'Vyhledávat pouze mezi\xa0ptačími druhy'
                                            }
                                        >
                                            <IconButton
                                                onClick={() => setBirdOnly(!birdOnly)}
                                                size={props.AutocompleteProps?.size || 'medium'}
                                                color={!birdOnly ? 'secondary' : undefined}
                                            >
                                                <Pets fontSize="inherit" />
                                            </IconButton>
                                        </TooltipMobile>
                                    </Box>
                                )}
                            </InputAdornment>
                        </>
                    ),
                },
            }}
            AutocompleteProps={{
                color: 'secondary',
                ...props.AutocompleteProps,
                renderOption: (props, option) => {
                    const labelLocal = getLocalLabel(option);
                    const labelLatin = getLatinLabel(option);

                    return (
                        <MenuItem
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{
                                '&.MuiMenuItem-root[aria-selected="true"]': {
                                    bgcolor: 'secondary.50',
                                },
                            }}
                        >
                            {currentLanguage !== 'la' && <>{labelLocal}</>}
                            <Typography variant="caption" component="em" sx={{ padding: '3px 0 0 1em' }}>
                                {labelLatin}
                            </Typography>
                        </MenuItem>
                    );
                },
                sx: {
                    ...props.AutocompleteProps?.sx,
                    ...(!isMobile && {
                        '& .Mui-focused .MuiInputAdornment-avifControls, \
                        &:hover .MuiInputAdornment-avifControls': {
                            display: 'flex',
                        },
                        '&.MuiAutocomplete-hasClearIcon .MuiInputAdornment-avifControls': {
                            right: '80px',
                        },
                        '&.MuiAutocomplete-hasPopupIcon .MuiInputBase-root': {
                            paddingRight:
                                // both buttons visible
                                enableLanguageSwitch && !disableNonBirdSpecies
                                    ? '235px'
                                    : // only language switcher
                                    enableLanguageSwitch
                                    ? '185px'
                                    : // only taxons switcher
                                    !disableNonBirdSpecies
                                    ? '95px'
                                    : undefined,
                        },
                        '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiInputBase-root': {
                            paddingRight:
                                // both buttons visible
                                enableLanguageSwitch && !disableNonBirdSpecies
                                    ? '250px'
                                    : // only language switcher
                                    enableLanguageSwitch
                                    ? '200px'
                                    : // only taxons switcher
                                    !disableNonBirdSpecies
                                    ? '110px'
                                    : undefined,
                        },
                    }),
                    ...(isMobile &&
                        enableLanguageSwitch && {
                            mt: 2.5,
                            '& .MuiInputAdornment-avifControls': {
                                right: 1,
                                top: '-18px',
                            },
                        }),
                    ...(currentLanguage === 'la' && {
                        '& .MuiAutocomplete-tag': {
                            fontStyle: 'italic',
                        },
                    }),
                },
            }}
            placeholder={props.placeholder ?? 'Pro vyhledání druhu začněte psát'}
            noResultsText={
                props.noResultsText ??
                `Musíte vybrat jeden z\xa0nabízených druhů. Pokud druh není znám, zadejte "neurčen".`
            }
            emptyInputText={props.emptyInputText ?? ' '}
            loadingText={props.loadingText ?? 'Načítám seznam druhů...'}
        />
    );
};

export default SpeciesAutocomplete;
