import { Marker, Popup, Tooltip } from 'react-leaflet';
import { IObservationMapped } from '../../../../../schemas/interfaces';
import { FC, useState } from 'react';
import L from 'leaflet';
import ObservationsListCard from './ObservationsListCard';
import ObservationSummaryCard from './ObservationSummaryCard';
import { Box } from '@mui/material';

export type IObservationGroup = IObservationMapped[];

const customIcon = new L.Icon({
    iconUrl: require('./location.svg').default,
    iconSize: new L.Point(40, 47),
});

const ObservationMarkerTooltip: FC<{ observations: IObservationGroup }> = ({ observations }) => {
    const [forceOpen, setForceOpen] = useState(false);

    return (
        <Marker
            position={[observations[0].location.coordinates.latitude, observations[0].location.coordinates.longitude]}
            icon={customIcon}
            eventHandlers={{
                mouseover: (s) => {
                    s.target.openPopup();
                },
                mouseout: (s) => {
                    if (!forceOpen) s.target.closePopup();
                },
                click: (s) => {
                    s.target.openPopup();
                    setForceOpen(true);
                },
            }}
        >
            <Popup
                offset={[0, -20]}
                eventHandlers={{
                    mouseover: (s) => {
                        console.log('tooltip in');
                    },
                    mouseout: () => {
                        console.log('tooltip out');
                    },
                }}
                closeButton={false}
            >
                <Box sx={{ width: '220px', maxWidth: '50vw', whiteSpace: 'normal', overflow: 'hidden' }}>
                    {observations.length > 1 && <ObservationsListCard observations={observations} />}
                    {observations.length === 1 && <ObservationSummaryCard observation={observations[0]} />}
                </Box>
            </Popup>
        </Marker>
    );
};

export default ObservationMarkerTooltip;
