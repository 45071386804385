import { FormikProps } from 'formik';
import { FC, PropsWithChildren } from 'react';
import { Chip } from '@mui/material';
import { ObservationItemFormValues } from '../../ObservationFormApp';
import { useDict } from '../../../../services/dictContext';
import { AvifDetectionIcon } from '../../../../components/icons';

interface TagsProps extends PropsWithChildren {
    formikProps: FormikProps<ObservationItemFormValues>;
    groupsEnabled: boolean;
}

const Tags: FC<TagsProps> = (props) => {
    const values = props.formikProps.values;
    const { detection: detections, tags } = useDict();

    const detection = detections?.find((d) => d.id === values.detectionId);

    return (
        <>
            {props.groupsEnabled && values.group && (
                <Chip label={`Skupina ${values.group}`} size="small" sx={{ bgcolor: 'primary.50' }}></Chip>
            )}
            {detection && (
                <Chip
                    label={detection.name.cs || 'Unknown detection'}
                    size="small"
                    sx={{ bgcolor: 'primary.50' }}
                    key={detection.label}
                    className={detection.label || ''}
                    icon={<AvifDetectionIcon detection={detection.label} fontSize="inherit" color="action" />}
                ></Chip>
            )}
            {props.children}
            {values.tags?.map((tagLabel) => (
                <Chip
                    label={tags ? tags.find((tag) => tag.label == tagLabel)?.name.cs || 'Unknown tag' : 'Loading...'}
                    size="small"
                    sx={{ bgcolor: 'primary.50' }}
                    key={tagLabel}
                    className={tagLabel}
                ></Chip>
            ))}
        </>
    );
};

export default Tags;
