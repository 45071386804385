import React, { useState, useEffect } from 'react';
import IObsListsResultsComponentProps from '../../../../interfaces/IObsListsResultsComponentProps';
import { useApi } from '../../../../services/apiContext';
import { IObservationMapped } from '../../../../schemas/interfaces';
import ObservationsMap from './map/ObservationsMap';
import { Box, Typography } from '@mui/material';
import { useAuth } from '../../../../services/authenticator';
import useDeepCompareEffect from 'use-deep-compare-effect';
import LongLoadingOverlay from '../../../../components/dataGrid/LongLoadingOverlay';

interface ObsItemsMapState {
    observations?: IObservationMapped[];
}

const ObsItemsMap: React.FunctionComponent<Required<IObsListsResultsComponentProps>> = (props) => {
    const [state, setState] = useState<ObsItemsMapState>({});
    const api = useApi();
    const { isLoggedIn } = useAuth();

    useDeepCompareEffect(() => {
        if (!props.query || isLoggedIn === undefined) return;

        setState((state) => ({ ...state, observations: undefined }));
        api.getObservationsMap({
            filter: props.query,
            fields: null,
            page: 1,
            pageSize: 1000,
        })
            .then((response) => {
                setState((state) => ({
                    ...state,
                    observations: response.items.filter((obs) => !!obs.location?.coordinates) as IObservationMapped[],
                }));
            })
            .catch((e) => {
                setState((state) => ({ ...state, observations: [] }));
                props.onFetchError && props.onFetchError(e);
            });
    }, [props.query, isLoggedIn]);

    return (
        <Box className="ObsItemsMap" sx={{ px: 2.25, py: 4 }}>
            {!state.observations && <LongLoadingOverlay />}
            {!!state.observations && !state.observations.length && (
                <>
                    <Typography variant="subtitle1" component="h2" textAlign="center">
                        Kritériím vyhledávání neodpovídají žádná pozorování.
                    </Typography>
                    <Typography variant="body2" component="p" textAlign="center">
                        Pozorování s&nbsp;polohou skrytou na&nbsp;úroveň kraje nejsou v&nbsp;mapě zobrazena.
                    </Typography>
                </>
            )}
            {!!state.observations && !!state.observations.length && (
                <ObservationsMap observations={state.observations} />
            )}
        </Box>
    );
};

export default ObsItemsMap;
