import { FunctionComponent } from 'react';
import { IAge } from '../../schemas/interfaces';
import { allDictionaryProvider } from '../../services/dictProviders';
import DictionarySelect, { DictionarySelectPublicProps } from './DictionarySelect';

/**
 * Implementation of DictionarySelect for age dictionary.
 */
const AgeSelect: FunctionComponent<DictionarySelectPublicProps<number, IAge>> = (props) => {
    return (
        <DictionarySelect<number, IAge>
            color="secondary"
            {...props}
            getDictOptions={
                new Promise((resolve, reject) => {
                    allDictionaryProvider
                        .onFirstValue()
                        .then((dicts) => {
                            resolve(dicts.age.sort((a, b) => a.id - b.id));
                        })
                        .catch((err) => {
                            reject(err);
                        });
                })
            }
            getDictValues={(dicts) => dicts.map((dict) => dict.id)}
            getOptionLabel={(option, dicts = [] as IAge[]) => {
                const dict = dicts.find((dict) => dict.id === option);

                if (!dict) return `Unknown age ${option}`;

                return `${dict.name.cs} ${dict.hint.cs ? `- ${dict.hint.cs}` : ''}`;
            }}
            getSubcategoryLevel={(option, dicts) => {
                if (!dicts) return 0;

                const age = dicts.find((dict) => dict.id === option);

                if (!age) return 0;

                return age.parents.length;
            }}
        />
    );
};

export default AgeSelect;
