import { FC } from 'react';
import { IObservationMapped } from '../../../../../schemas/interfaces';
import { Divider, Grid, Typography } from '@mui/material';
import ObservationSummaryCard from './ObservationSummaryCard';

const ObservationsListCard: FC<{ observations: IObservationMapped[] }> = ({ observations }) => {
    return (
        <Grid container className="ObservationsListCard">
            <Grid item xs={12}>
                <Typography component="h4" variant="body2" fontWeight="500">
                    {observations.length} pozorování
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    maxHeight: 'min(60vh, 200px)',
                    p: 1,
                    overflow: 'auto',
                }}
            >
                <Grid container spacing={1}>
                    {observations.map((observation) => (
                        <Grid item xs={12}>
                            <ObservationSummaryCard key={observation.itemId} observation={observation} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ObservationsListCard;
