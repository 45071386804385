import { FC, useEffect, useState } from 'react';
import { IActivity } from '../../schemas/interfaces';
import { allDictionaryProvider } from '../../services/dictProviders';
import UndefinedDictValue from './UndefinedDictValue';

const ActivityFromDict: FC<{ activityId?: number | null }> = ({ activityId }) => {
    const [dict, setDict] = useState<IActivity[]>();

    useEffect(() => {
        allDictionaryProvider.onFirstValue().then((dict) => setDict(dict.activity));
    }, []);

    if (!activityId) return <UndefinedDictValue />;

    if (!dict) return <>Loading...</>;

    const activity = dict.find((activity) => activity.id === activityId);

    if (!activity) return <>Unknown activity</>;

    return <>{activity.name.cs || 'Missing activity label'}</>;
};

export default ActivityFromDict;
