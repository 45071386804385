import { FunctionComponent } from 'react';
import { IRarity } from '../../schemas/interfaces';
import { allDictionaryProvider } from '../../services/dictProviders';
import DictionarySelect, { DictionarySelectPublicProps } from './DictionarySelect';

const RaritySelect: FunctionComponent<DictionarySelectPublicProps<number, IRarity>> = (props) => {
    return (
        <DictionarySelect<number, IRarity>
            color="secondary"
            {...props}
            getDictOptions={
                new Promise((resolve, reject) => {
                    allDictionaryProvider
                        .onFirstValue()
                        .then((dicts) => {
                            resolve(dicts.rarity);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                })
            }
            getDictValues={(dicts) => dicts.map((dict) => dict.level)}
            getOptionLabel={(option, dicts = [] as IRarity[]) =>
                dicts.find((dict) => dict.level === option)?.name.cs || `Unknown attribute ${option}`
            }
        />
    );
};

export default RaritySelect;
