import React from 'react';
import { LatLngBoundsExpression } from 'leaflet';
import { IObservationMapped } from '../../../../schemas/interfaces';
import FitBounds from './FitBounds';

interface ObservationsFitBoundsProps {
    observations: {
        location?: {
            coordinates?: IObservationMapped['location']['coordinates'] | null;
        };
    }[];
    onlyOnce?: boolean;
}

const ObservationsFitBounds: React.FC<ObservationsFitBoundsProps> = ({ observations, onlyOnce }) => {
    const bounds: LatLngBoundsExpression = observations
        .map((obs) => {
            if (obs.location?.coordinates) {
                const { latitude, longitude } = obs.location.coordinates;
                return [latitude, longitude] as [number, number];
            }
            return null;
        })
        .filter((coord): coord is [number, number] => coord !== null) || [
        [49.8175, 15.473],
        [48.8175, 16.473],
    ]; // Default bounds (Czech Republic)

    return <FitBounds bounds={bounds} onlyOnce={onlyOnce} />;
};

export default ObservationsFitBounds;
