import { Dialog, DialogContent } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { FunctionComponent, useState } from 'react';
import ImageCaption from './ImageCaption';
import { IMedia } from '../../schemas/interfaces';

interface MasonryImageProps {
    image: IMedia;
    caption?: JSX.Element | string;
    hideIcon?: boolean;
    sx?: SxProps;
    fullSize?: boolean;
    onClick?: () => void;
    disableDialog?: boolean;
    onObservationClick?: () => void;
    onListClick?: () => void;
    taxonName?: string;
    fixedAspectRatio?: boolean;
    transparentCaption?: boolean;
}

const MasonryImage: FunctionComponent<MasonryImageProps> = (props) => {
    const { image, sx, hideIcon, onClick, disableDialog } = props;
    const [open, setOpen] = useState(false);

    return (
        <>
            <Box
                key={image.id}
                className="MasonryImage"
                onClick={() => {
                    if (onClick) onClick();
                    if (!disableDialog) setOpen(true);
                }}
                sx={{
                    position: 'relative',
                    width: '100%',
                    minHeight: '100px',
                    cursor: 'pointer',
                    aspectRatio: props.fixedAspectRatio ? '1/1' : undefined,
                    ...sx,
                }}
            >
                <Box
                    component="img"
                    src={props.fullSize ? image.url : image.thumbnail}
                    alt={image.note || ''}
                    loading="lazy"
                    sx={{
                        display: 'block',
                        width: '100%',
                        maxWidth: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                    }}
                />
                <ImageCaption
                    image={image}
                    caption={props.caption}
                    hideIcons={hideIcon}
                    onObservationClick={props.onObservationClick}
                    onListClick={props.onListClick}
                    taxonName={props.taxonName}
                    transparent={props.transparentCaption}
                />
            </Box>
            <Dialog open={open && !disableDialog} onClose={() => setOpen(false)} maxWidth="lg">
                <DialogContent sx={{ padding: 0, position: 'relative' }}>
                    {open && (
                        <>
                            <img
                                src={image.url}
                                alt={image.note || ''}
                                style={{
                                    display: 'block',
                                    width: '100%',
                                    maxHeight: 'calc(100vh - 64px)',
                                }}
                            />
                            <ImageCaption image={image} caption={props.caption} hideIcons={hideIcon} />
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default MasonryImage;
