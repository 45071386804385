import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './authenticator';
import { useApi } from './apiContext';
import { IUser } from '../schemas/interfaces';

export interface IUserContext extends Partial<IUser> {
    refetchUser?: () => Promise<IUserContext>;
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    const [user, setUser] = useState<IUserContext>();
    const { isLoggedIn } = useAuth();
    const { getUser } = useApi();

    useEffect(() => {
        if (isLoggedIn === undefined) return;

        getUser().then((user) => {
            setUser(user);
        });
    }, [isLoggedIn]);

    const refetchUser = async () => {
        const user = await getUser();
        setUser(user);
        return user;
    };

    return <UserContext.Provider value={{ ...user, refetchUser }}>{children}</UserContext.Provider>;
};
