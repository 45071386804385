import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { LatLngBoundsExpression } from 'leaflet';

interface FitBoundsProps {
    bounds: LatLngBoundsExpression;
    onlyOnce?: boolean;
}

const FitBounds: React.FC<FitBoundsProps> = ({ bounds, onlyOnce }) => {
    const [zoomed, setZoomed] = useState(false);
    const map = useMap();

    useEffect(() => {
        if (onlyOnce && zoomed) return;

        if (Array.isArray(bounds) && bounds.length) {
            map.fitBounds(bounds, { padding: [100, 100] });
            setZoomed(true);
        }
    }, [bounds, map, onlyOnce, zoomed]);
    return null;
};

export default FitBounds;
