import { FC } from 'react';
import { IObservationMapped } from '../../../../../schemas/interfaces';
import OpenStreetMap, { MapCategory } from '../../../../../components/map/OpenStreetMap';
import MarkerClusterGroup from 'react-leaflet-cluster';
import ObservationMarkerTooltip, { IObservationGroup } from './ObservationMarkerTooltip';
import { Typography, useMediaQuery } from '@mui/material';
import ObservationsFitBounds from '../ObservationsFitBounds';
import MapToImage from '../../../../../components/map/MapToImage';

const ObservationsMap: FC<{ observations?: IObservationMapped[] }> = ({ observations }) => {
    const obsMap = new Map<string, IObservationGroup>();
    const isMobile = useMediaQuery('(pointer: coarse)');

    observations?.forEach((observation) => {
        const { latitude, longitude } = observation.location.coordinates;
        const obs = obsMap.get(`${latitude},${longitude}`);
        obsMap.set(`${latitude},${longitude}`, obs ? [...obs, observation] : [observation]);
    });

    return (
        <OpenStreetMap
            height="500px"
            defaultZoom={isMobile ? 6 : undefined}
            sx={{ position: 'relative' }}
            mapCategory={MapCategory.SEARCH}
            enableFullscreen
        >
            <MapToImage />
            <Typography
                variant="body2"
                component="span"
                fontWeight="bold"
                sx={{
                    position: 'absolute',
                    top: '0.3em',
                    bgcolor: 'background.paper',
                    zIndex: 450,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    py: 0.25,
                    px: 1,
                    borderRadius: 'var(--mui-shape-borderRadius)',
                }}
            >
                Pozorování s&nbsp;polohou skrytou na&nbsp;úroveň kraje nejsou zobrazena.
            </Typography>
            <MarkerClusterGroup
                chunkedLoading
                onClick={(e: any) => console.log('onClick', e)}
                maxClusterRadius={50}
                spiderfyOnMaxZoom={true}
                showCoverageOnHover={false}
            >
                {!!observations &&
                    Array.from(obsMap).map(([key, observations]) => (
                        <ObservationMarkerTooltip key={key} observations={observations} />
                    ))}
            </MarkerClusterGroup>
            {!!observations && <ObservationsFitBounds observations={observations} />}
        </OpenStreetMap>
    );
};

export default ObservationsMap;
