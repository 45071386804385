import { FC, useEffect, useState } from 'react';
import { ISex } from '../../schemas/interfaces';
import { allDictionaryProvider } from '../../services/dictProviders';
import UndefinedDictValue from './UndefinedDictValue';

const SexFromDict: FC<{ sexId?: number | null }> = ({ sexId }) => {
    const [dict, setDict] = useState<ISex[]>();

    useEffect(() => {
        allDictionaryProvider.onFirstValue().then((dict) => setDict(dict.sex));
    }, []);

    if (!sexId) return <UndefinedDictValue />;

    if (!dict) return <>Loading...</>;

    const sex = dict.find((sex) => sex.id === sexId);

    if (!sex) return <>Unknown sex</>;

    return <>{sex.name.cs || 'Missing sex label'}</>;
};

export default SexFromDict;
